import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import  GestGemsysService  from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

export class StatCoursClasse extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rcode_enseign: 'Tous',
            liste_data: [],
            total_prev: 0,
            total_disp: 0,
            total_rest: 0,
            prc_disp: 0,
            prc_rest: 0,
            liste_enseignement: [{ label: 'Enseignement', value: 'Tous' }],
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let rcode_enseign = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        this.setState({
            showindicator: true,
            total_prev: 0,
            total_disp: 0,
            total_rest: 0,
            prc_disp: 0,
            prc_rest: 0,
        });
        this.gemsysService.get_liste_stat_cours_classe(spd1, spd2, rcode_enseign).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let ttp = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.val1
                    }, initialValue);
                    let ttd = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.val2
                    }, initialValue);
                    let ttr = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.val3
                    }, initialValue);
                    this.setState({
                        total_prev: ttp,
                        total_disp: ttd,
                        total_rest: ttr,
                        prc_disp: this.gemsysService.formatDecimal(100*ttd/ttp)+'%',
                        prc_rest: this.gemsysService.formatDecimal(100*ttr/ttp)+'%'
                    });
                }
            }
        });
    }

    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('DD/MM/YYYY');
        let spd2 = moment(this.state.pd2).format('DD/MM/YYYY');
        let rcode_enseign = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        this.gemsysService.generer_stat_cours_classe(spd1, spd2, rcode_enseign, format);
    }

    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_data();
    }

   
    render() {
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={2} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.total_prev} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.total_disp} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.prc_disp} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.total_rest} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.prc_rest} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd1" placeholder="Date début" dateFormat="dd/mm/yy" value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2" >
                                    <Calendar id="pd2" placeholder="Date fin" value={this.state.pd2} onChange={e => this.setState({ pd2: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_enseignement} placeholder="Sélectionner" value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}  ref={(el) => { this.dt = el; }} footerColumnGroup={footerGroup}
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={100}>
                                <Column field="ordre" header="#" style={{ width: '5%' }} />
                                <Column field="lib" header="Classe" style={{ width: '43%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="val1" header="TT_prévu" style={{ width: '9%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="val2" header="TT_disp" style={{ width: '12%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="sval2" header="%_disp" style={{ width: '11%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="val3" header="TT_rest" style={{ width: '10%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="sval3" header="%_rest" style={{ width: '10%' }} sortable={true} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}