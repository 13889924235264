import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService  from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import moment from 'moment';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
 
export class StatEffectif extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(),
            pd2: new Date(),
            rcode_enseign: 'Tous',
            rmodule: 'Module 1',
            liste_module: [
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
                { value: 'Tous', label: 'Année' }
            ],
            liste_data: [],
            nb: 0,
            nbf: 0,
            nbf_prc: 0,
            nbg: 0,
            nbg_prc: 0,
            nbbour: 0,
            nbbour_prc: 0,
            nbexpte: 0,
            nbexpte_prc: 0,
            nbautre: 0,
            nbautre_prc: 0,
            liste_enseignement: [{ label: 'Enseignement', value: 'Tous' }],
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }

    get_liste_data() {
        let rcode_enseign = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        this.setState({
            showindicator: true,
            nb: 0,
            nbf: 0,
            nbf_prc: 0,
            nbg: 0,
            nbg_prc: 0,
            nbbour: 0,
            nbbour_prc: 0,
            nbexpte: 0,
            nbexpte_prc: 0,
            nbautre: 0,
            nbautre_prc: 0,
        });
        this.gemsysService.get_liste_stat_effectif(rcode_enseign, this.state.rmodule).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let nbg = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nbg
                    }, initialValue);
                    let nb = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nb
                    }, initialValue);
                    let nbf = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nbf
                    }, initialValue);
                    let nbautre = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nbautre
                    }, initialValue);
                    let nbbour = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nbbour
                    }, initialValue);
                    let nbexpte = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.nbexpte
                    }, initialValue);
                    this.setState({
                        nb: nb,
                        nbf: nbf,
                        nbf_prc: this.gemsysService.formatDecimal(100*nbf/nb)+'%',
                        nbg: nbg,
                        nbg_prc: this.gemsysService.formatDecimal(100*nbg/nb)+'%',
                        nbbour: nbbour,
                        nbbour_prc: this.gemsysService.formatDecimal(100*nbbour/nb)+'%',
                        nbexpte: nbexpte,
                        nbexpte_prc: this.gemsysService.formatDecimal(100*nbexpte/nb)+'%',
                        nbautre: nbautre,
                        nbautre_prc: this.gemsysService.formatDecimal(100*nbautre/nb)+'%',
                    });
                }
            }
        });
    }

    conf_print(format) {
        let rcode_enseign = 'Tous';
        if (this.state.rcode_enseign !== undefined && this.state.rcode_enseign !== null && this.state.rcode_enseign !== 'Tous') {
            rcode_enseign = this.state.rcode_enseign;
        }
        this.gemsysService.generer_stat_effectif(rcode_enseign, this.state.rmodule, format);
    }

    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_data();
    }


    render() {
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={2} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.nb} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbf} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbf_prc} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg_prc} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbbour} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbbour_prc} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbexpte} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbexpte_prc} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbautre} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbautre_prc} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <Dropdown options={this.state.liste_enseignement} placeholder="Sélectionner" value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <SplitButton label="Rechercher" style={{ width: '170px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '700px' }} ref={(el) => { this.dt = el; }}
                                 footerColumnGroup={footerGroup} dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={100}>
                                <Column field="ordre" header="#" style={{ width: '4%' }} />
                                <Column field="classe" header="Classe" style={{ width: '22%' }} sortable/>
                                <Column field="nb" header="TT" style={{ width: '6%' }} sortable />
                                <Column field="nbf" header="TF" style={{ width: '6%' }} sortable />
                                <Column field="nbf_prc" header="%_F" style={{ width: '7%' }} sortable />
                                <Column field="nbg" header="TG" style={{ width: '6%' }} sortable />
                                <Column field="nbg_prc" header="%_G" style={{ width: '7%' }} sortable />
                                <Column field="nbbour" header="TBO" style={{ width: '7%' }} sortable />
                                <Column field="nbbour_prc" header="%_BO" style={{ width: '7%' }} sortable />
                                <Column field="nbexpte" header="TEX" style={{ width: '7%' }} sortable />
                                <Column field="nbexpte_prc" header="%_EX" style={{ width: '7%' }} sortable />
                                <Column field="nbautre" header="TA" style={{ width: '7%' }} sortable />
                                <Column field="nbautre_prc" header="%_A" style={{ width: '7%' }} sortable />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}