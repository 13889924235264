import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import GestGemsysService  from '../service/GestGemsysService';
import './styles.css';
import { Panel } from 'primereact/panel';
import { InputNumber } from 'primereact/inputnumber';

export class FormValidationInscription extends Component {
    constructor() {
        super();
        this.state = {
            rclasse: 'Tous',
            liste_inscriptions: null,
            liste_classes: [],
            selectedInscriptions: [],
            msg: '',
            modif: false,
            visible: false,
            mtTranche1: 0,
            mtTranche2: 0,
            mtTranche3: 0,
            fraisInscription: 0,
            fraisReinscription: 0,
            fraisBadge: 0,
            showindicator: false,
        };

        this.fraisScolariteEditor = this.fraisScolariteEditor.bind(this);
        this.fraisBadgeEditor = this.fraisBadgeEditor.bind(this);
        this.fraisInscReinscEditor = this.fraisInscReinscEditor.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_inscriptions: updateds });
    }

    inputNumberEditor(props, field, vmax) {
        return <InputNumber disabled={props.rowData[vmax]==0} min={0} max={props.rowData[vmax]} value={props.rowData[field]} onChange={(e) => {
            if (e.value > props.rowData[vmax]) {
                this.onEditorValueChange(props, props.rowData[vmax])
            } else {
                this.onEditorValueChange(props, e.value)
            }
        }} />;
    }

    fraisScolariteEditor(props) {
        return this.inputNumberEditor(props, 'fraisScolarite', 'maxfraisScolarite');
    }

    fraisBadgeEditor(props) {
        return this.inputNumberEditor(props, 'fraisBadge', 'maxfraisBadge');
    }

    fraisInscReinscEditor(props) {
        return this.inputNumberEditor(props, 'fraisInscReinsc', 'maxfraisInscReinsc');
    }


    componentDidMount() {
        this.gestGemsysService.getClasse().then(data => this.setState({ liste_classes: data }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_inscrit_for_validation();
            this.setState({selectedInscriptions: []});
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    get_liste_inscrit_for_validation() {
        this.setState({
            fraisBadge: 0,
            fraisInscription: 0,
            fraisReinscription: 0,
            mtTranche1: 0,
            mtTranche2: 0,
            mtTranche3: 0,
        });
        this.setState({showindicator: true});
        this.gestGemsysService.getListe_inscrit_for_validation(this.state.rclasse).then(data => this.setState({ liste_inscriptions: data }, () => {
            this.setState({showindicator: false});
            if (data !== null && data !== undefined && data !== '' && data.length > 0) {
                this.setState({
                    fraisBadge: this.gestGemsysService.formaterValueSep(parseFloat(data[0].codeClasse.fraisBadge)),
                    fraisInscription: this.gestGemsysService.formaterValueSep(parseFloat(data[0].codeClasse.fraisInscription)),
                    fraisReinscription: this.gestGemsysService.formaterValueSep(parseFloat(data[0].codeClasse.fraisReinscription)),
                    mtTranche1: this.gestGemsysService.formaterValueSep(parseFloat(data[0].codeClasse.mtTranche1)),
                    mtTranche2: this.gestGemsysService.formaterValueSep(parseFloat(data[0].codeClasse.mtTranche2)),
                    mtTranche3: this.gestGemsysService.formaterValueSep(parseFloat(data[0].codeClasse.mtTranche3)),
                })
            }
        }));
    }

    valider_inscriptions() {
        if (this.state.selectedInscriptions == null || this.state.selectedInscriptions == undefined || this.state.selectedInscriptions.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes' });
        } else {
            this.gestGemsysService.ValiderInscrit(this.state.selectedInscriptions).then(data => {
                if (data != null && data != undefined && data.code == '200') {
                    this.resultat(data.code, "Validation réussie");
                } else this.resultat(data.code, data.contenu);
            });
        }
    }



    photoTemplate(rowData, column) {
        let fic = rowData.matricule.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gestGemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    delete_inscriptions() {
        if (this.state.selectedInscriptions == null || this.state.selectedInscriptions == undefined || this.state.selectedInscriptions.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.gestGemsysService.DeleteInscritBloc(this.state.selectedInscriptions).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_inscription() {
        if (this.state.selectedInscriptions == null || this.state.selectedInscriptions == undefined || this.state.selectedInscriptions.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes à supprimer' });
        } else {
            this.setState({ visible: true });
        }
    }
    render() {
        let rclasses = null;
        if (this.state.liste_classes != null && this.state.liste_classes != undefined) {
            rclasses = this.state.liste_classes.map(x => {
                let lc=this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université'?'S':'';
                return { value: x.codeClasse, label: lc+x.libClasse + " " + x.optClasse }
            });
        }
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_inscriptions();
                }} label="Oui" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer les lignes sélectionnées ?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <hr />
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <label >Classe *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown options={rclasses} placeholder="Selectionner la classe" value={this.state.rclasse} onChange={(e) => this.setState({ rclasse: e.value }, () => {
                                        this.get_liste_inscrit_for_validation();
                                    })} autoWidth={false} filter filterMode='contains' />
                                </div>
                                <div className="col-12 md:col-4">
                                    <Button label="Rechercher" loading={this.state.showindicator} icon="pi pi-search" onClick={() => this.get_liste_inscrit_for_validation()} />
                                </div>
                            </div>
                            <Panel header="Frais">
                                <center>
                                    <div className="grid">
                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.state.mtTranche1}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Tranche1</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.state.mtTranche2}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Tranche2</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.state.mtTranche3}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Tranche3</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.state.fraisInscription}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Inscription</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.state.fraisReinscription}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Réinscription</span>
                                        </div>

                                        <div className="col-12 md:col-2">
                                            <label style={{ color: 'green', fontWeight: 'bold', fontSize: 18 }}>{this.state.fraisBadge}</label><br />
                                            <span style={{ color: '#B4BABD' }}>Frais badge</span>
                                        </div>
                                    </div>
                                </center>
                            </Panel> 
                            <DataTable value={this.state.liste_inscriptions} style={{ minHeight: '700px' }}
                                 className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}
                                onSelectionChange={e => this.setState({ selectedInscriptions: e.value })}
                                selection={this.state.selectedInscriptions} >
                                <Column selectionMode="multiple" style={{ width: '3%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="matricule.prenomEleve" body={(data) => {
                                    return <div>
                                        {data.matricule.nomEleve + " " + data.matricule.prenomEleve}
                                    </div>
                                }} header="Nom et Prénoms" style={{ width: '15' }} filter={true} filterMatchMode='contains' />
                                <Column field="numStatut.statutFinancier" body={(rowData, column) => {
                                    return <div>
                                        {rowData.numStatut.statutFinancier + " " + rowData.numStatut.statutAccademique}
                                    </div>
                                }} header="Statut" style={{ width: '15%' }} filter={true} filterMatchMode='contains' />
                                <Column field="typeInsc" header="Type" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="fraisScolarite" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.fraisScolarite))}</span>
                                    </div>
                                }} header="Scolarité" style={{ width: '10%' }} editor={this.fraisScolariteEditor} filter={true} filterMatchMode='contains' />
                                <Column field="fraisInscReinsc" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.fraisInscReinsc))}</span>
                                    </div>
                                }} header="Inscription" style={{ width: '10%' }} editor={this.fraisInscReinscEditor} filter={true} filterMatchMode='contains' />
                                <Column field="fraisBadge" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.fraisBadge))}</span>
                                    </div>
                                }} header="Badge" style={{ width: '10%' }} editor={this.fraisBadgeEditor} filter={true} filterMatchMode='contains' />

                            </DataTable>
                            <br />
                            <center>
                                <div className="grid" style={{ width: 400 }}>
                                    <div className="col"><Button label="Supprimer" loading={this.state.showindicator} className="p-button-danger" icon="pi pi-times" onClick={() => this.conf_delete_inscription()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.valider_inscriptions()} /></div>
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
