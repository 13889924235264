import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import { InputText } from 'primereact/inputtext';
import './styles.css';
import { Panel } from 'primereact/panel';
import moment from 'moment';
 
export class FormDossier extends Component {
    constructor() {
        super();
        this.state = {
            userconnected: null,
            annee: new Date().getFullYear(),
            contact: '',
            email: '',
            dateOuv: new Date(),
            dateFerm: '',
            denregDoc: new Date(),
            matrPers1: '',
            matrPers2: '',
            matrPers3: '',
            matrPers4: '',
            matrPers5: '',
            idEtabl: 1,
            idDossier: 0,
            idSrce: 'NA',
            arrt1: '',
            arrt2: '',
            arrt3: '',
            dl: '',
            noteService: '',
            selectedDossier: null,
            dossiers: null,
            personnels: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };
        this.edit_dossier = this.edit_dossier.bind(this);
        this.conf_delete_dossier = this.conf_delete_dossier.bind(this);
        this.enreg_dossier = this.enreg_dossier.bind(this);
        this.conf_delete_dossier = this.conf_delete_dossier.bind(this);
        this.gemsysService = new GestGemsysService();
        this.setState({ userconnected: this.gemsysService.get_userconnected() });
    }
    componentDidMount() {
        this.gemsysService.getPersonnel().then(data => this.setState({ personnels: data }));
        this.gemsysService.getDossier().then(data => this.setState({ dossiers: data }));
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_dossier();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    edit_dossier(data) {
        if (data != null && data != undefined) {
            this.setState({ annee: data.annee });
            this.setState({ idSrce: data.idSrce });
            this.setState({ contact: data.contact });
            this.setState({ email: data.email });
            this.setState({ dateOuv: new Date(moment(data.dateOuv, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ dateFerm: new Date(moment(data.dateFerm, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ matrPers1: data.matrPers1.matrPers });
            this.setState({ matrPers2: data.matrPers2.matrPers });
            this.setState({ matrPers3: data.matrPers3.matrPers });
            this.setState({ matrPers4: data.matrPers4.matrPers });
            this.setState({ matrPers5: data.matrPers5.matrPers });
            this.setState({ arrt1: data.arrt1 });
            this.setState({ arrt2: data.arrt2 });
            this.setState({ arrt3: data.arrt3 });
            this.setState({ dl: data.dl });
            this.setState({ noteService: data.noteService });
            this.setState({ selectedDossier: data });
            this.setState({ modif: true });
            window.scrollTo(0, 0);
        }
    }
    annuler_dossier() {
        this.setState({ annee: '' });
        this.setState({ contact: '' });
        this.setState({ email: '' });
        this.setState({ dateOuv: '' });
        this.setState({ dateFerm: '' });
        this.setState({ denregDoc: '' });
        this.setState({ matrPers1: '' });
        this.setState({ matrPers2: '' });
        this.setState({ matrPers3: '' });
        this.setState({ matrPers4: '' });
        this.setState({ matrPers5: '' });
        this.setState({ idEtabl: '' });
        this.setState({ idDossier: '' });
        this.setState({ iduser: '' });
        this.setState({ arrt1: '' });
        this.setState({ arrt2: '' });
        this.setState({ arrt3: '' });
        this.setState({ dl: '' });
        this.setState({ idSrce: 'NA' });
        this.setState({ noteService: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    } 
    enreg_dossier() {
        if (this.state.dateOuv === "" || this.state.annee === "" || this.state.matrPers1 === "" || this.state.matrPers2 === "" || this.state.matrPers3 === "" || this.state.matrPers4 === "" || this.state.matrPers5 === "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            let date_fin = moment(this.state.dateFerm, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (date_fin == 'Invalid date') {
                date_fin = null;
            }
            this.setState({showindicator: true});
            if (this.state.modif == false) {
                let dossier = {
                    annee: this.state.annee,
                    contact: this.state.contact,
                    email: this.state.email,
                    dateOuv: moment(this.state.dateOuv, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    dateFerm: date_fin,
                    idDossier: '0',
                    arrt1: this.state.arrt1,
                    arrt2: this.state.arrt2,
                    arrt3: this.state.arrt3,
                    dl: this.state.dl,
                    idSrce: this.state.idSrce,
                    noteService: this.state.noteService,
                    matrPers5: { matrPers: this.state.matrPers5 },
                    matrPers4: { matrPers: this.state.matrPers4 },
                    matrPers2: { matrPers: this.state.matrPers2 },
                    matrPers3: { matrPers: this.state.matrPers3 },
                    matrPers1: { matrPers: this.state.matrPers1 }
                };
                this.gemsysService.AddDossier(dossier).then(data => this.resultat(data.code, data.contenu));
            } else {
                let dossier = this.state.selectedDossier;
                dossier.annee = this.state.annee;
                dossier.contact = this.state.contact;
                dossier.email = this.state.email;
                dossier.dateOuv = moment(this.state.dateOuv, 'DD/MM/YYYY').format('DD/MM/YYYY');
                dossier.dateFerm = date_fin;
                dossier.arrt1 = this.state.arrt1;
                dossier.arrt2 = this.state.arrt2;
                dossier.arrt3 = this.state.arrt3;
                dossier.dl = this.state.dl;
                dossier.noteService = this.state.noteService;
                dossier.matrPers5 = { matrPers: this.state.matrPers5 };
                dossier.matrPers4 = { matrPers: this.state.matrPers4 };
                dossier.matrPers2 = { matrPers: this.state.matrPers2 };
                dossier.matrPers3 = { matrPers: this.state.matrPers3 };
                dossier.matrPers1 = { matrPers: this.state.matrPers1 };
                this.gemsysService.UpdateDossier(dossier).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }
    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_dossier(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_dossier(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -22 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_dossier() {
        if (this.state.selectedDossier === null || this.state.selectedDossier === undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gemsysService.DeleteDossier(this.state.selectedDossier).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_dossier(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedDossier: data });
            this.setState({ msg: "Le Dossier n° " + data.idDossier });
            this.setState({ visible: true });
        }
    }
    render() {
        let listepersonnels = null;
        let liste_srce = [{ value: 'NA', label: 'Non applicable' }];
        if (this.state.personnels !== null && this.state.personnels !== undefined && this.state.personnels.length > 0) {
            listepersonnels = this.state.personnels.map(x => {
                return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers, categoriePers: x.categorie }
            });
        }
        if (this.state.dossiers !== null && this.state.dossiers !== undefined && this.state.dossiers.length > 0) {
            let liste = this.state.dossiers.map(x => {
                return { value: x.idDossier, label: x.annee }
            });
            liste_srce = liste_srce.concat(liste);
        }

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_dossier();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog> 
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <Panel>
                                <div className="grid">
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="annee">Annee</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputText id="annee" value={this.state.annee} onChange={(e) => this.setState({ annee: e.target.value })} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="contact">Contact</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputText id="contact" value={this.state.contact} onChange={(e) => this.setState({ contact: e.target.value })} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputText id="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="dateOuv">Date ouverture</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Calendar id="dateOuv" value={this.state.dateOuv} onChange={(e) => this.setState({ dateOuv: e.target.value })}></Calendar>
                                    </div>  
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="dateFerm">Date fermeture</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Calendar id="dateFerm" value={this.state.dateFerm} onChange={e => this.setState({ dateFerm: e.target.value })}></Calendar>
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="matrPers1">Fondateur *</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Dropdown id="matrPers1" options={listepersonnels} optionLabel="label" value={this.state.matrPers1} onChange={e => this.setState({ matrPers1: e.value })} itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-2">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-7">
                                                            <label>{option.label}</label>
                                                        </div>
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.categoriePers}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} panelStyle={{ width: '500px' }} filter filterMode='contains' />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="matrPers2">Chargé Scolarité *</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Dropdown id="matrPers2" options={listepersonnels} optionLabel="label" value={this.state.matrPers2} onChange={e => this.setState({ matrPers2: e.value })} itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-2">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-7">
                                                            <label>{option.label}</label>
                                                        </div>
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.categoriePers}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} panelStyle={{ width: '500px' }} filter filterMode='contains' />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="matrPers3">{this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'Recteur' : 'DG'} *</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Dropdown id="matrPers3" options={listepersonnels} optionLabel="label" value={this.state.matrPers3} onChange={e => this.setState({ matrPers3: e.value })} itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-2">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-7">
                                                            <label>{option.label}</label>
                                                        </div>
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.categoriePers}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} panelStyle={{ width: '500px' }} filter filterMode='contains' />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="matrPers4">{this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ? 'Vice recteur' : 'DGA/DE'} *</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Dropdown id="matrPers4" options={listepersonnels} optionLabel="label" value={this.state.matrPers4} onChange={e => this.setState({ matrPers4: e.value })} itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-2">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-7">
                                                            <label>{option.label}</label>
                                                        </div>
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.categoriePers}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} panelStyle={{ width: '500px' }} filter filterMode='contains' />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="matrPers5">DAAF *</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Dropdown id="matrPers5" options={listepersonnels} optionLabel="label" value={this.state.matrPers5} onChange={e => this.setState({ matrPers5: e.value })} itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-2">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-7">
                                                            <label>{option.label}</label>
                                                        </div>
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.categoriePers}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} panelStyle={{ width: '500px' }} filter filterMode='contains'  />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="cmtDossier">Libelle1</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputTextarea id="arrt1" value={this.state.arrt1} rows={3} onChange={(e) => this.setState({ arrt1: e.target.value })} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="cmtDossier">Libelle2</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputTextarea id="arrt2" value={this.state.arrt2} rows={3} onChange={(e) => this.setState({ arrt2: e.target.value })} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="cmtDossier">Libelle3</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputTextarea id="arrt3" value={this.state.arrt3} rows={2} onChange={(e) => this.setState({ arrt3: e.target.value })} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="dropdown">Source copie</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <Dropdown id="issueDossier" options={liste_srce} value={this.state.idSrce} onChange={e => this.setState({ idSrce: e.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="dl">Déliberation</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputText id="dl" value={this.state.dl} onChange={(e) => this.setState({ dl: e.target.value })} />
                                    </div>
                                    <div className="col-3 md:col-2">
                                        <label htmlFor="dl">Note service</label>
                                    </div>
                                    <div className="col-9 md:col-4">
                                        <InputText id="dl" value={this.state.noteService} onChange={(e) => this.setState({ noteService: e.target.value })} />
                                    </div>
                                </div>
                            </Panel>
                            <center>
                                <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annuler_dossier()} /></div>
                                    <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_dossier()} /></div>
                                </div>
                            </center>
                            <DataTable value={this.state.dossiers} paginatorPosition="top" selectionMode="single" paginator={true} rows={10}
                                alwaysShowPaginator={false} onRowClick={e => this.setState({ selectedDossier: e.data })} 
                                onRowSelect={e => this.setState({ selectedDossier: e.data })} style={{ minHeight: '400px' }}
                                selection={this.state.selectedDossier} dataKey="idDossier" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" >
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '15%' }} />
                                <Column field="annee" header="Année" style={{ width: '22%' }} />
                                <Column field="contact" header="Contact" style={{ width: '25%' }} />
                                <Column field="email" header="Email" body={(rowData, Column) => {
                                    return <div>
                                        <span style={{ color: 'blue' }}>{rowData.email}</span>
                                    </div>
                                }} style={{ width: '38%' }} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}