import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import GestGemsysService from '../service/GestGemsysService';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Chart } from 'primereact/chart';
import { Panel } from 'primereact/panel';


export default class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            rid_etabl: 'Tous',
            liste_etabl: [{ value: 'Tous', label: 'Etablissement', nom: 'Etablissement' }],
            liste_stat_effectif_statut: [],
            liste_stat_effectif_annee: [],
            liste_stat_effectif_sexe_classe: [],
            nb: 0,
            nbg: 0,
            nbf: 0,
            nb: 0,
            nb_global: 0,
            nbt: 0,
            nbexampte: 0,
            tt_recette_annee: 0,
            tt_recette_jour: 0,
            tt_depense_annee: 0,
            tt_depense_jour: 0,
            solde_annee: 0,
            solde_jour: 0,
            taux_recouvrement: 0,
            taux_cours_disp: 0,
            tt_recette_prev: 0,
            tt_recette_rest: 0,
            showindicator: false,
            nbtrf: 0,
            nbabn: 0,
            prc_f: 0,
            prc_g: 0,
            color_solde: 'black',
        };
        this.gemsysService = new GestGemsysService();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.typeOp !== this.props.match.params.typeOp) {
            this.setState({ rid_etabl: this.gemsysService.get_userconnected().idEtabl.idEtabl }, () => {
                this.get_dashboard();
            })
        }
    }

    get_liste_etablissement() {
        this.gemsysService.getEtablissement().then(data => {
            let liste = null;
            if (data !== null && data !== undefined) {
                liste = data.map(x => {
                    return { value: x.idEtabl.idEtabl, label: x.idEtabl.codeEtabl, nom: x.idEtabl.nomEtabl }
                });
            }
            this.setState({ liste_etabl: this.state.liste_etabl.concat(liste) }, () => {
                this.setState({ rid_etabl: this.gemsysService.get_userconnected().idEtabl.idEtabl }, () => {
                    this.get_dashboard();
                })
            });
        });
    }

    get_dashboard() {
        this.setState({
            liste_stat_effectif_statut: [],
            liste_stat_effectif_annee: [],
            liste_stat_effectif_sexe_classe: [],
            nb: 0,
            nbg: 0,
            nbf: 0,
            nbabn: 0,
            nbtrf: 0,
            nb_global: 0,
            nbt: 0,
            nbexampte: 0,
            tt_recette_annee: 0,
            tt_recette_jour: 0,
            tt_depense_annee: 0,
            tt_depense_jour: 0,
            solde_annee: 0,
            solde_jour: 0,
            taux_recouvrement: 0,
            taux_cours_disp: 0,
            tt_recette_prev: 0,
            tt_recette_rest: 0,
            color_solde: 'black',
        });

        this.gemsysService.get_dashboard(this.state.rid_etabl).then(data => {
            if (data !== null && data !== undefined && data !== '') {
                this.setState({
                    liste_stat_effectif_statut: data.liste_stat_effectif_statut,
                    liste_stat_effectif_annee: data.liste_stat_effectif_annee,
                    liste_stat_effectif_sexe_classe: data.liste_stat_effectif_sexe_classe,
                }, () => {
                    let nb = 0;
                    let nbg = 0;
                    let nbf = 0;
                    let color_solde = 'black';
                    if (this.state.liste_stat_effectif_sexe_classe != null && this.state.liste_stat_effectif_sexe_classe != undefined && this.state.liste_stat_effectif_sexe_classe.length > 0) {
                        for (var i = this.state.liste_stat_effectif_sexe_classe.length; i--;) {
                            nb += this.state.liste_stat_effectif_sexe_classe[i].val3;
                            nbf += this.state.liste_stat_effectif_sexe_classe[i].val2;
                            nbg += this.state.liste_stat_effectif_sexe_classe[i].val1;
                        }
                    }
                    if (data.solde_annee > 0) {
                        color_solde = 'green';
                    } else {
                        color_solde = 'red';
                    }
                    this.setState({
                        nb: nb,
                        nbg: nbg,
                        nbf: nbf,
                        nb_global: data.nb_global,
                        nbt: data.nb,
                        nbabn: data.nbabn,
                        nbtrf: data.nbtrf,
                        color_solde: color_solde,
                        nbexampte: this.gemsysService.formaterValueSep(data.nbexampte),
                        tt_recette_annee: this.gemsysService.formaterValueSep(data.tt_recette_annee),
                        tt_recette_jour: this.gemsysService.formaterValueSep(data.tt_recette_jour),
                        tt_depense_annee: this.gemsysService.formaterValueSep(data.tt_depense_annee),
                        tt_depense_jour: this.gemsysService.formaterValueSep(data.tt_depense_jour),
                        solde_annee: this.gemsysService.formaterValueSep(data.solde_annee),
                        solde_jour: this.gemsysService.formaterValueSep(data.solde_jour),
                        taux_recouvrement: data.taux_recouvrement,
                        taux_cours_disp: data.taux_cours_disp,
                        tt_recette_prev: this.gemsysService.formaterValueSep(data.tt_recette_prev),
                        tt_recette_rest: this.gemsysService.formaterValueSep(data.tt_recette_rest),
                        prc_f: this.gemsysService.formatDecimal(100*nbf / (nb == 0 ? 1 : nb))
                    });
                })
            }
        });
    }

    componentDidMount() {
        this.get_liste_etablissement();
    }

    render() {
        let liste_label_stat_effectif = null;
        let liste_ds_stat_effectif = null;
        let liste_ds_stat_abandon = null;

        let liste_label = ['G', 'F'];
        let liste_ds_stat = [this.state.nbg, this.state.nbf];

        let liste_label_doughnut = [];
        let liste_ds_doughnut = [];

        if (this.state.liste_stat_effectif_annee != null && this.state.liste_stat_effectif_annee != undefined) {
            if (this.state.liste_stat_effectif_annee.length > 0) {
                liste_label_stat_effectif = this.state.liste_stat_effectif_annee.map(x => {
                    return x.lib
                });
                liste_ds_stat_effectif = this.state.liste_stat_effectif_annee.map(x => {
                    return x.val
                });
                liste_ds_stat_abandon = this.state.liste_stat_effectif_annee.map(x => {
                    return x.val2
                });
            }
        }

        if (this.state.liste_stat_effectif_statut != null && this.state.liste_stat_effectif_statut != undefined) {
            if (this.state.liste_stat_effectif_statut.length > 0) {
                liste_label_doughnut = this.state.liste_stat_effectif_statut.map(x => {
                    return x.lib
                });
                liste_ds_doughnut = this.state.liste_stat_effectif_statut.map(x => {
                    return x.val
                });
            }
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    backgroundColor: [
                        "#33485e",
                        "#ef6e85",
                    ],
                    data: liste_ds_stat
                }
            ]
        };

        const chart_data_stat_effectif = {
            labels: liste_label_stat_effectif,
            datasets: [
                {
                    label: 'Valide',
                    backgroundColor: '#50b883',
                    borderColor: '#50b883',
                    fill: true,
                    data: liste_ds_stat_effectif
                },
                {
                    label: 'Abandon',
                    backgroundColor: '#33485e',
                    borderColor: '#33485e',
                    fill: true,
                    data: liste_ds_stat_abandon
                }
            ]
        };

        const doughnut_chart_data = {
            labels: liste_label_doughnut,
            datasets: [
                {
                    backgroundColor: [
                        "#003366",
                        "#66ccff",
                        "#66cc99",
                        "#cccc66",
                        "#9999cc",
                        "#9933cc",
                        "#3366ff",
                    ],
                    data: liste_ds_doughnut
                }
            ]
        };


        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={1} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.nbg} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nbf} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.nb} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="layout-dashboard">
                {this.state.liste_etabl.length > 1 ?
                    <div className="grid" style={{ width: 300, marginTop: -35, marginBottom: -40 }}>
                        <div className="col-10">
                            <Dropdown options={this.state.liste_etabl} placeholder="Séléctionner" value={this.state.rid_etabl} onChange={(e) => this.setState({ rid_etabl: e.target.value }, () => {
                                this.get_dashboard();
                            })} filter={true} filterBy='label' filterMatchMode='contains' style={{ width: '100%' }} />
                        </div>
                        <div className="col-1">
                            <Button type="button" style={{ width: '30px', height: '33px', marginLeft: -25 }} icon="pi pi-refresh" onClick={() => this.get_dashboard()} />
                        </div>
                        <div className="col-1">
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                        </div>
                    </div>
                    : null
                }
                <div className="grid">

                    <div className="col-12 md:col-6 xl:col-3">
                        <div className="card grid-nogutter widget-overview-box widget-overview-box-1" style={{ height: 120 }}>
                            <span className="overview-icon">
                                <i className="pi pi-users"></i>
                            </span>
                            <span className="overview-title">Effectif</span>
                            <div className="grid overview-detail">
                                <div className="col-6">
                                    <div className="overview-number">{this.state.nb_global}</div>
                                    <div className="overview-subtext">Global</div>
                                </div>
                                <div className="col-6">
                                    <div className="overview-number">{this.state.nbt}</div>
                                    <div className="overview-subtext">Valide</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 xl:col-3">
                        <div className="card grid-nogutter widget-overview-box widget-overview-box-3" style={{ height: 120 }}>
                            <span className="overview-icon">
                                <i className="pi pi-dollar"></i>
                            </span>
                            <span className="overview-title">Recette</span>
                            <div className="grid overview-detail">
                                <div className="col-6">
                                    <div className="overview-number">{this.state.tt_recette_annee}</div>
                                    <div className="overview-subtext">Total</div>
                                </div>
                                <div className="col-6">
                                    <div className="overview-number">{this.state.tt_recette_jour}</div>
                                    <div className="overview-subtext">du jour</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 xl:col-3">
                        <div className="card grid-nogutter widget-overview-box widget-overview-box-2" style={{ height: 120 }}>
                            <span className="overview-icon">
                                <i className="pi pi-wallet"></i>
                            </span>
                            <span className="overview-title">Dépenses</span>
                            <div className="grid overview-detail">
                                <div className="col-6">
                                    <div className="overview-number">{this.state.tt_depense_annee}</div>
                                    <div className="overview-subtext">Total</div>
                                </div>
                                <div className="col-6">
                                    <div className="overview-number">{this.state.tt_depense_jour}</div>
                                    <div className="overview-subtext">du jour</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 xl:col-3">
                        <div className="card grid-nogutter widget-overview-box widget-overview-box-4" style={{ height: 120 }}>
                            <span className="overview-icon">
                                <i className="pi pi-briefcase"></i>
                            </span>
                            <span className="overview-title">Solde</span>
                            <div className="grid overview-detail">
                                <div className="col-6">
                                    <div className="overview-number" style={{ color: this.state.color_solde }}>{this.state.solde_annee}</div>
                                    <div className="overview-subtext">Général</div>
                                </div>
                                <div className="col-6">
                                    <div className="overview-number">{this.state.solde_jour}</div>
                                    <div className="overview-subtext">du jour</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12" style={{ marginTop: -10, marginBottom: -25 }}>
                        <div className="grid">
                            <div className="col-6 p-sm-6 md:col-3">
                                <Panel className="dashboard-box">
                                    <center>
                                        <i className="pi pi-check-circle" style={{ fontSize: 16, color: '#6c757d' }}></i><label style={{ fontSize: 16, color: '#6c757d', marginLeft: 10 }}>Taux recouvrement</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 22, color: '#000', fontWeight: 'bold' }}>{this.state.taux_recouvrement} %</label>
                                    </center>
                                </Panel>
                            </div>
                            <div className="col-6 p-sm-6 md:col-3">
                                <Panel className="dashboard-box">
                                    <center>
                                        <i className="pi pi-shopping-cart" style={{ fontSize: 16, color: '#6c757d' }}></i><label style={{ fontSize: 16, color: '#6c757d', marginLeft: 10 }}>Récette privisionnelle</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 22, color: '#000', fontWeight: 'bold' }}>{this.state.tt_recette_prev}</label>
                                    </center>
                                </Panel>
                            </div>
                            <div className="col-6 p-sm-6 md:col-3">
                                <Panel className="dashboard-box">
                                    <center>
                                        <i className="pi pi-bell" style={{ fontSize: 16, color: '#6c757d' }}></i><label style={{ fontSize: 16, color: '#6c757d', marginLeft: 10 }}>Reste recouvrement</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 22, color: 'red', fontWeight: 'bold' }}>{this.state.tt_recette_rest}</label>
                                    </center>
                                </Panel>
                            </div>
                            <div className="col-6 p-sm-6 md:col-3">
                                <Panel className="dashboard-box">
                                    <center>
                                        <i className="pi pi-book" style={{ fontSize: 16, color: '#6c757d' }}></i><label style={{ fontSize: 16, color: '#6c757d', marginLeft: 10 }}>Taux évolution cours</label>
                                    </center>
                                    <center>
                                        <label style={{ fontSize: 22, color: 'red', fontWeight: 'bold' }}>{this.state.taux_cours_disp} %</label>
                                    </center>
                                </Panel>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-6">
                        <div className="card" style={{ minHeight: '475px' }}>
                            <i className="pi pi-chart-line"></i> Statistiques par classe
                            <hr />
                            <DataTable value={this.state.liste_stat_effectif_sexe_classe}  footerColumnGroup={footerGroup}
                              rows={9} dataKey="lib" paginator className="p-datatable-gridlines" showGridlines responsiveLayout="scroll">
                                <Column field="lib" header="Classe" sortable={true} />
                                <Column field="val1" header="G" sortable={true} />
                                <Column field="val2" header="F"  sortable={true} />
                                <Column field="val3" header="T" sortable={true} />
                            </DataTable>
                        </div>
                    </div>

                    <div className="col-12 md:col-6">
                        <div className="card" style={{ minHeight: '475px' }}>
                            <i className="pi pi-chart-line"></i> Statistiques par statut
                            <hr />
                            <div className="revenue-chart-container">
                                <div className="flex justify-content-center">
                                    <Chart type="doughnut" data={doughnut_chart_data} style={{ position: 'relative', width: '59%' }} />
                                </div>
                            </div>
                            <hr />
                           
                            <center>
                                <div className="grid">
                                    <div className="col-4 md:col-4">
                                        <label style={{ fontWeight: 'bold', fontSize: 22 }} >{this.state.nbabn} </label>
                                    </div>
                                    <div className="col-4 md:col-4">
                                        <label style={{ fontWeight: 'bold', fontSize: 22 }} >{this.state.nbtrf}</label>
                                    </div>
                                    <div className="col-4 md:col-4">
                                        <label style={{ fontWeight: 'bold', fontSize: 22 }} >{this.state.prc_f} %</label>
                                    </div>
                                    <div className="col-4 md:col-4" style={{ marginTop: -20 }}>
                                        <label >Nb abandon</label>
                                    </div>
                                    <div className="col-4 md:col-4" style={{ marginTop: -20 }}>
                                        <label>Nb transféré</label>
                                    </div>
                                    <div className="col-4 md:col-4" style={{ marginTop: -20 }}>
                                        <label >Filles</label>
                                    </div>
                                </div>
                            </center>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card" >
                            <i className="pi pi-chart-line"></i> Statistiques des effectifs par année
                            <hr />
                            <Chart responsive={true} type="line" data={chart_data_stat_effectif} height="100w" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
