import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppSearch from './AppSearch';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';

import Dashboard from './components/Dashboard';
import { FormDossier } from './components/FormDossier';
import { FormFonction } from './components/FormFonction';
import { FormPromo } from './components/FormPromo';
import { FormFacDep } from './components/FormFacDep';
import { FormComptesUsers } from './components/FormComptesUsers';
import { FormSemestre } from './components/FormSemestre';
import { FormMatiere } from './components/FormMatiere';
import { FormStatut } from './components/FormStatut';
import { FormSalle } from './components/FormSalle';
import { FormDepense } from './components/FormDepense';
import { FormEncaissement } from './components/FormEncaissement';
import { FormEmargement } from './components/FormEmargement';
import { FormInscription } from './components/FormInscription';
import { FormReinscriptionBloc } from './components/FormReinscriptionBloc';
import { FormValidationInscription } from './components/FormValidationInscription';
import { FormPersonnel } from './components/FormPersonnel';
import { FormEmprunt } from './components/FormEmprunt';
import { FormAbsence } from './components/FormAbsence';
import { FormDocument } from './components/FormDocument';
import { FormEmploiTemps } from './components/FormEmploiTemps';
import { FormMission } from './components/FormMission';
import { FormPaie } from './components/FormPaie';
import { FormSaisieNote } from './components/FormSaisieNote';
import { StatCoursClasse } from './components/StatCoursClasse';
import { StatCoursMatiere } from './components/StatCoursMatiere';
import { StatGnleDep } from './components/StatGnleDep';
import { StatEffectif } from './components/StatEffectif';
import { StatResultat } from './components/StatResultat';
import { EditionAssignation } from './components/EditionAssignation';
import { EditionJournalTrace } from './components/EditionJournalTrace';
import { EditionEmploiTemps } from './components/EditionEmploiTemps';
import { EditionReçus } from './components/EditionReçus';
import { EditionEncaissement } from './components/EditionEncaissement';
import { EditionSituationFrais } from './components/EditionSituationFrais';
import { EditionEtatPaie } from './components/EditionEtatPaie';
import { EditionAbsence } from './components/EditionAbsence';
import { EditionPersonnelDocs } from './components/EditionPersonnelDocs';
import { EditionBilanAnnuel } from './components/EditionBilanAnnuel';
import { EditionEtudiantDoc } from './components/EditionEtudiantDoc';
import { EditionResultats } from './components/EditionResultats';
import { EditionNotes } from './components/EditionNotes';
import { FormChangepwd } from './components/FormChangepwd';
import { FormQuestionnaire } from './components/FormQuestionnaire';
import { FormPlanning } from './components/FormPlanning';
import { FormEvalSondage } from './components/FormEvalSondage';
import { FormEvent } from './components/FormEvent';
import { FormMessage } from './components/FormMessage';
import { FormTheme } from './components/FormTheme';
import { EditionResultatSondage } from './components/EditionResultatSondage';



import PrimeReact from 'primereact/api';
import { Tooltip } from "primereact/tooltip";

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

import GestGemsysService from './service/GestGemsysService';

const App = () => {

    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [colorScheme, setColorScheme] = useState('light');
    const [menuTheme, setMenuTheme] = useState('layout-sidebar-darkgray');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] = useState(false);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const [logoColor, setLogoColor] = useState('white');
    const [componentTheme, setComponentTheme] = useState('blue');
    const [logoUrl, setLogoUrl] = useState('assets/layout/images/logo-dark.png');
    const copyTooltipRef = useRef();
    const location = useLocation();


    let menuClick = false;
    let searchClick = false;
    let userMenuClick = false;
    let notificationMenuClick = false;
    let rightMenuClick = false;
    let configClick = false;


    const appservice = new GestGemsysService();

    var menu = [];
    var profil_suer = '';
    var userconnected = appservice.get_userconnected();

    if (userconnected !== null && userconnected !== undefined) {
        profil_suer = userconnected.profilu;
    }

    if (profil_suer == 'Administrateur') {
        menu = [
            {
                label: "Favorites", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard/0" },
                    { label: "Gestion encaissement", icon: "pi pi-fw pi-credit-card", to: "/encaissement" },
                    { label: "Gestion dépenses", icon: "pi pi-fw pi-money-bill", to: "/depense" },
                ]
            },
            { separator: true },
            {
                label: "Scolarité", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Gestion scolarité", icon: "pi pi-fw pi-users",
                        items: [
                            { label: "Inscription", icon: "pi pi-fw pi-book", to: '/inscription/new' },
                            { label: "Mises à jour", icon: "pi pi-fw pi-user-edit", to: '/inscription/liste' },
                            { label: "Réinscription en bloc", icon: "pi pi-fw pi-user", to: "/reinscription" }
                        ]
                    },
                    {
                        label: "Gestion Note", icon: "pi pi-fw pi-pencil",
                        items: [
                            { label: "Saisie note", icon: "pi pi-fw pi-pencil", to: "/note/saisie" },
                            { label: "Mises à jour note", icon: "pi pi-fw pi-pencil", to: "/note/modification" },
                            { label: "Validation note", icon: "pi pi-fw pi-check-square", to: "/note/validation" },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Opération", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Validation inscription", icon: "pi pi-fw pi-money-bill", to: "/validation_inscription" },
                    { label: "Gestion émargements", icon: "pi pi-fw pi-list", to: "/emargement" },
                    { label: "Gestion des absences", icon: "pi pi-fw pi-user-minus", to: "/absence" },
                    { label: "Gestion des documents", icon: "pi pi-fw pi-folder-open", to: "/document" },
                    {
                        label: "Gestion RH", icon: "pi pi-fw pi-users",
                        items: [
                            { label: "Gestion du personnel", icon: "pi pi-fw pi-users", to: "/personnel" },
                            { label: "Gestion des fonctions", icon: "pi pi-fw pi-sitemap", to: "/fonction" },
                            { label: "Gestion des emprunts", icon: "pi pi-fw pi-wallet", to: "/emprunt" },
                            { label: "Gestion paie", icon: "pi pi-fw pi-money-bill", to: "/paie" },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Editions", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Dossiers scolaires", icon: "pi pi-fw pi-print", to: "/dossier_scolaire" },
                            { label: "Absences", icon: "pi pi-fw pi-print", to: "/ed_absence" },
                            { label: "Personnels et docs", icon: "pi pi-fw pi-print", to: "/ed_personnel" },
                            { label: "Bilan annuel", icon: "pi pi-fw pi-print", to: "/bilan" },
                            { label: "Etat de paie", icon: "pi pi-fw pi-print", to: "/etat_paie" },
                            { label: "Situation des frais", icon: "pi pi-fw pi-print", to: "/situation_vers" },
                            { label: "Encaissements", icon: "pi pi-fw pi-print", to: "/ed_encaissement" },
                            { label: "Edition notes", icon: "pi pi-fw pi-print", to: "/notes" },
                            { label: "Edition résultats", icon: "pi pi-fw pi-print", to: "/resultats" },
                            { label: "Edition des reçus", icon: "pi pi-fw pi-print", to: "/recus" },
                            { label: "Emploi de temps", icon: "pi pi-fw pi-print", to: "/ed_emploi" },
                            { label: "Résultats sondages", icon: "pi pi-fw pi-print", to: "/resultat_sondage" },
                            { label: "Journal des traces", icon: "pi pi-fw pi-print", to: '/traces' },
                            { label: "Edition assignation", icon: "pi pi-fw pi-print", to: '/assignation' },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-line",
                        items: [
                            { label: "Stat. gnle effectif", icon: "pi pi-fw pi-chart-line", to: '/stat_effectif' },
                            { label: "Stat. des résultats", icon: "pi pi-fw pi-chart-line", to: '/stat_resultat' },
                            { label: "Stat. des dépenses", icon: "pi pi-fw pi-chart-line", to: '/stat_depense' },
                            { label: "Stat. cours par matière", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_matiere' },
                            { label: "Stat. cours par classe", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_classe' },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Paramétrages", icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "Paramétrages", icon: "pi pi-fw pi-cog",
                        items: [
                            { label: "Dossier", icon: "pi pi-fw pi-cog", to: "/dossiers" },
                            { label: "Enseignement", icon: "pi pi-fw pi-cog", to: "/enseignement" },
                            { label: "Emploi de temps", icon: "pi pi-fw pi-cog", to: "/emploi" },
                            { label: "Comptes utilisateurs", icon: "pi pi-fw pi-cog", to: "/users" },
                            { label: "Classes", icon: "pi pi-fw pi-cog", to: "/classe" },
                            { label: "Matières", icon: "pi pi-fw pi-cog", to: "/matiere" },
                            { label: "Promotions", icon: "pi pi-fw pi-cog", to: "/promotion" },
                            { label: "Salles", icon: "pi pi-fw pi-cog", to: "/salle" },
                            { label: "Statut", icon: "pi pi-fw pi-cog", to: "/statut" },
                            { label: "Missions", icon: "pi pi-fw pi-cog", to: "/mission" },

                        ]
                    },
                    {
                        label: "Messagerie", icon: "pi pi-fw pi-envelope",
                        items: [
                            { label: "Evènements", icon: "pi pi-fw pi-calendar-plus", to: "/evenement" },
                            { label: "Gestion messages", icon: "pi pi-fw pi-envelope", to: "/messagerie" }
                        ]
                    },
                    {
                        label: "Sondages", icon: "pi pi-fw pi-filter",
                        items: [
                            { label: "Questionnaires", icon: "pi pi-fw pi-question", to: "/questionnaire" },
                            { label: "Planification", icon: "pi pi-fw pi-calendar-plus", to: "/planing" },
                            { label: "Résultats", icon: "pi pi-fw pi-book", to: "/sondage/resultats" },
                            { label: "Sondage", icon: "pi pi-fw pi-check-square", to: "/sondage/new" }
                        ]
                    },
                    { label: "Gestion thèmes", icon: "pi pi-fw pi-comments", to: "/theme" },
                ]
            },

        ];
    } else if (profil_suer == 'Comptable') {
        menu = [
            {
                label: "Favorites", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard/0" },
                    { label: "Gestion encaissement", icon: "pi pi-fw pi-credit-card", to: "/encaissement" },
                    { label: "Gestion dépenses", icon: "pi pi-fw pi-money-bill", to: "/depense" },
                ]
            },

            { separator: true },
            {
                label: "Opération", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Validation inscription", icon: "pi pi-fw pi-money-bill", to: "/validation_inscription" },
                    { label: "Gestion émargements", icon: "pi pi-fw pi-list", to: "/emargement" },
                    {
                        label: "Gestion RH", icon: "pi pi-fw pi-users",
                        items: [
                            { label: "Gestion du personnel", icon: "pi pi-fw pi-users", to: "/personnel" },
                            { label: "Gestion des fonctions", icon: "pi pi-fw pi-sitemap", to: "/fonction" },
                            { label: "Gestion des emprunts", icon: "pi pi-fw pi-wallet", to: "/emprunt" },
                            { label: "Gestion paie", icon: "pi pi-fw pi-money-bill", to: "/paie" },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Editions", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Personnels et docs", icon: "pi pi-fw pi-print", to: "/ed_personnel" },
                            { label: "Bilan annuel", icon: "pi pi-fw pi-print", to: "/bilan" },
                            { label: "Etat de paie", icon: "pi pi-fw pi-print", to: "/etat_paie" },
                            { label: "Situation des frais", icon: "pi pi-fw pi-print", to: "/situation_vers" },
                            { label: "Encaissements", icon: "pi pi-fw pi-print", to: "/ed_encaissement" },
                            { label: "Edition des reçus", icon: "pi pi-fw pi-print", to: "/recus" },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-line",
                        items: [
                            { label: "Stat. gnle effectif", icon: "pi pi-fw pi-chart-line", to: '/stat_effectif' },
                            { label: "Stat. des dépenses", icon: "pi pi-fw pi-chart-line", to: '/stat_depense' },
                            { label: "Stat. cours par matière", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_matiere' },
                            { label: "Stat. cours par classe", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_classe' },
                        ]
                    },
                ]
            },
        ];
    } else if (profil_suer == 'Recteur' || profil_suer == 'Vice Recteur') {
        menu = [
            {
                label: "Favorites", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard/0" },
                    { label: "Validation note", icon: "pi pi-fw pi-check-square", to: "/note/validation" },
                ]
            },
            { separator: true },
            {
                label: "Scolarité", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Gestion scolarité", icon: "pi pi-fw pi-users",
                        items: [
                            { label: "Inscription", icon: "pi pi-fw pi-book", to: '/inscription/new' },
                            { label: "Mises à jour", icon: "pi pi-fw pi-user-edit", to: '/inscription/liste' },
                            { label: "Réinscription en bloc", icon: "pi pi-fw pi-user", to: "/reinscription" }
                        ]
                    },
                    {
                        label: "Gestion Note", icon: "pi pi-fw pi-pencil",
                        items: [
                            { label: "Saisie note", icon: "pi pi-fw pi-pencil", to: "/note/saisie" },
                            { label: "Mises à jour note", icon: "pi pi-fw pi-pencil", to: "/note/modification" },
                            { label: "Validation note", icon: "pi pi-fw pi-check-square", to: "/note/validation" },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Opération", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Gestion émargements", icon: "pi pi-fw pi-list", to: "/emargement" },
                    { label: "Gestion des absences", icon: "pi pi-fw pi-user-minus", to: "/absence" },
                    { label: "Gestion des documents", icon: "pi pi-fw pi-folder-open", to: "/document" },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Editions", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Dossiers scolaires", icon: "pi pi-fw pi-print", to: "/dossier_scolaire" },
                            { label: "Absences", icon: "pi pi-fw pi-print", to: "/ed_absence" },
                            { label: "Personnels et docs", icon: "pi pi-fw pi-print", to: "/ed_personnel" },
                            { label: "Bilan annuel", icon: "pi pi-fw pi-print", to: "/bilan" },
                            { label: "Etat de paie", icon: "pi pi-fw pi-print", to: "/etat_paie" },
                            { label: "Situation des frais", icon: "pi pi-fw pi-print", to: "/situation_vers" },
                            { label: "Encaissements", icon: "pi pi-fw pi-print", to: "/ed_encaissement" },
                            { label: "Edition notes", icon: "pi pi-fw pi-print", to: "/notes" },
                            { label: "Edition résultats", icon: "pi pi-fw pi-print", to: "/resultats" },
                            { label: "Edition des reçus", icon: "pi pi-fw pi-print", to: "/recus" },
                            { label: "Emploi de temps", icon: "pi pi-fw pi-print", to: "/ed_emploi" },
                            { label: "Résultats sondages", icon: "pi pi-fw pi-print", to: "/resultat_sondage" },
                            { label: "Edition assignation", icon: "pi pi-fw pi-print", to: '/assignation' },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-line",
                        items: [
                            { label: "Stat. gnle effectif", icon: "pi pi-fw pi-chart-line", to: '/stat_effectif' },
                            { label: "Stat. des résultats", icon: "pi pi-fw pi-chart-line", to: '/stat_resultat' },
                            { label: "Stat. des dépenses", icon: "pi pi-fw pi-chart-line", to: '/stat_depense' },
                            { label: "Stat. cours par matière", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_matiere' },
                            { label: "Stat. cours par classe", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_classe' },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Paramétrages", icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "Messagerie", icon: "pi pi-fw pi-envelope",
                        items: [
                            { label: "Evènements", icon: "pi pi-fw pi-calendar-plus", to: "/evenement" },
                            { label: "Gestion messages", icon: "pi pi-fw pi-envelope", to: "/messagerie" }
                        ]
                    },
                    {
                        label: "Sondages", icon: "pi pi-fw pi-filter",
                        items: [
                            { label: "Questionnaires", icon: "pi pi-fw pi-question", to: "/questionnaire" },
                            { label: "Planification", icon: "pi pi-fw pi-calendar-plus", to: "/planing" },
                            { label: "Résultats", icon: "pi pi-fw pi-book", to: "/sondage/resultats" },
                            { label: "Sondage", icon: "pi pi-fw pi-check-square", to: "/sondage/new" }
                        ]
                    },
                    { label: "Gestion thèmes", icon: "pi pi-fw pi-comments", to: "/theme" },
                ]
            },

        ];
    } else if (profil_suer == 'Doyen faculté' || profil_suer == 'Chef departement') {
        menu = [
            {
                label: "Favorites", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard/0" },
                    { label: "Saisie note", icon: "pi pi-fw pi-pencil", to: "/note/saisie" },
                    { label: "Consulter note", icon: "pi pi-fw pi-pencil", to: "/note/modification" },
                ]
            },
            { separator: true },
            {
                label: "Opération", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Gestion émargements", icon: "pi pi-fw pi-list", to: "/emargement" },
                    { label: "Gestion des absences", icon: "pi pi-fw pi-user-minus", to: "/absence" },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Editions", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Dossiers scolaires", icon: "pi pi-fw pi-print", to: "/dossier_scolaire" },
                            { label: "Absences", icon: "pi pi-fw pi-print", to: "/ed_absence" },
                            { label: "Edition notes", icon: "pi pi-fw pi-print", to: "/notes" },
                            { label: "Edition résultats", icon: "pi pi-fw pi-print", to: "/resultats" },
                            { label: "Emploi de temps", icon: "pi pi-fw pi-print", to: "/ed_emploi" },
                            { label: "Résultats sondages", icon: "pi pi-fw pi-print", to: "/resultat_sondage" },
                            { label: "Edition assignation", icon: "pi pi-fw pi-print", to: '/assignation' },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-line",
                        items: [
                            { label: "Stat. gnle effectif", icon: "pi pi-fw pi-chart-line", to: '/stat_effectif' },
                            { label: "Stat. des résultats", icon: "pi pi-fw pi-chart-line", to: '/stat_resultat' },
                            { label: "Stat. cours par matière", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_matiere' },
                            { label: "Stat. cours par classe", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_classe' },
                        ]
                    },
                ]
            },

        ];
    } else if (profil_suer == 'Chargé scolarité') {
        menu = [
            {
                label: "Favorites", icon: "pi pi-fw pi-home",
                items: [
                    { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/dashboard/0" },
                    { label: "Gestion des absences", icon: "pi pi-fw pi-user-minus", to: "/absence" },
                    { label: "Gestion des documents", icon: "pi pi-fw pi-folder-open", to: "/document" },
                ]
            },
            { separator: true },
            {
                label: "Scolarité", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Gestion scolarité", icon: "pi pi-fw pi-users",
                        items: [
                            { label: "Inscription", icon: "pi pi-fw pi-book", to: '/inscription/new' },
                            { label: "Mises à jour", icon: "pi pi-fw pi-user-edit", to: '/inscription/liste' },
                            { label: "Réinscription en bloc", icon: "pi pi-fw pi-user", to: "/reinscription" }
                        ]
                    },
                    {
                        label: "Gestion Note", icon: "pi pi-fw pi-pencil",
                        items: [
                            { label: "Saisie note", icon: "pi pi-fw pi-pencil", to: "/note/saisie" },
                            { label: "Mises à jour note", icon: "pi pi-fw pi-pencil", to: "/note/modification" },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Reporting", icon: "pi pi-fw pi-id-users",
                items: [
                    {
                        label: "Editions", icon: "pi pi-fw pi-print",
                        items: [
                            { label: "Dossiers scolaires", icon: "pi pi-fw pi-print", to: "/dossier_scolaire" },
                            { label: "Absences", icon: "pi pi-fw pi-print", to: "/ed_absence" },
                            { label: "Personnels et docs", icon: "pi pi-fw pi-print", to: "/ed_personnel" },
                            { label: "Edition notes", icon: "pi pi-fw pi-print", to: "/notes" },
                            { label: "Edition résultats", icon: "pi pi-fw pi-print", to: "/resultats" },
                            { label: "Emploi de temps", icon: "pi pi-fw pi-print", to: "/ed_emploi" },
                            { label: "Résultats sondages", icon: "pi pi-fw pi-print", to: "/resultat_sondage" },
                            { label: "Edition assignation", icon: "pi pi-fw pi-print", to: '/assignation' },
                        ]
                    },
                    {
                        label: "Statistiques", icon: "pi pi-fw pi-chart-line",
                        items: [
                            { label: "Stat. gnle effectif", icon: "pi pi-fw pi-chart-line", to: '/stat_effectif' },
                            { label: "Stat. des résultats", icon: "pi pi-fw pi-chart-line", to: '/stat_resultat' },
                            { label: "Stat. cours par matière", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_matiere' },
                            { label: "Stat. cours par classe", icon: "pi pi-fw pi-chart-line", to: '/stat_cours_classe' },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: "Paramétrages", icon: "pi pi-fw pi-cog",
                items: [
                    {
                        label: "Paramétrages", icon: "pi pi-fw pi-cog",
                        items: [
                            { label: "Dossier", icon: "pi pi-fw pi-cog", to: "/dossiers" },
                            { label: "Enseignement", icon: "pi pi-fw pi-cog", to: "/enseignement" },
                            { label: "Emploi de temps", icon: "pi pi-fw pi-cog", to: "/emploi" },
                            { label: "Classes", icon: "pi pi-fw pi-cog", to: "/classe" },
                            { label: "Matières", icon: "pi pi-fw pi-cog", to: "/matiere" },
                            { label: "Promotions", icon: "pi pi-fw pi-cog", to: "/promotion" },
                            { label: "Salles", icon: "pi pi-fw pi-cog", to: "/salle" },
                            { label: "Statut", icon: "pi pi-fw pi-cog", to: "/statut" },
                            { label: "Missions", icon: "pi pi-fw pi-cog", to: "/mission" },

                        ]
                    },
                    {
                        label: "Messagerie", icon: "pi pi-fw pi-envelope",
                        items: [
                            { label: "Evènements", icon: "pi pi-fw pi-calendar-plus", to: "/evenement" },
                            { label: "Gestion messages", icon: "pi pi-fw pi-envelope", to: "/messagerie" }
                        ]
                    },
                    { label: "Gestion thèmes", icon: "pi pi-fw pi-comments", to: "/theme" },
                ]
            },

        ];
    }
    const routers = [
        { path: '/dashboard/:typeOp', component: Dashboard, exact: true, meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Dashboard' }] } },
        { path: '/dossiers', component: FormDossier, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Dossiers' }] } },
        { path: '/fonction', component: FormFonction, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Fonction' }] }, },
        { path: '/promotion', component: FormPromo, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Promotion' }] } },
        { path: '/enseignement', component: FormFacDep, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Enseignement' }] } },
        { path: '/users', component: FormComptesUsers, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Comptes utilisateurs' }] } },
        { path: '/classe', component: FormSemestre, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Classes' }] } },
        { path: '/matiere', component: FormMatiere, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Matières' }] } },
        { path: '/statut', component: FormStatut, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Statut' }] } },
        { path: '/chpwd', component: FormChangepwd, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Changer mot de passe' }] } },

        { path: '/salle', component: FormSalle, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Salles' }] } },
        { path: '/depense', component: FormDepense, exact: true, meta: { breadcrumb: [{ parent: 'Opération', label: 'Dépenses' }] } },
        { path: '/encaissement', component: FormEncaissement, exact: true, meta: { breadcrumb: [{ parent: 'Opération', label: 'Encaissement' }] } },
        { path: '/emargement', component: FormEmargement, exact: true, meta: { breadcrumb: [{ parent: 'Opération', label: 'Emargement' }] } },
        { path: '/inscription/:typeOp', component: FormInscription, exact: true, meta: { breadcrumb: [{ parent: 'Scolarité', label: 'Inscription' }] } },
        { path: '/reinscription', component: FormReinscriptionBloc, exact: true, meta: { breadcrumb: [{ parent: 'Scolarité', label: 'Réinscription en bloc' }] } },
        { path: '/validation_inscription', component: FormValidationInscription, exact: true, meta: { breadcrumb: [{ parent: 'Scolarité', label: 'Validation inscription' }] } },
        { path: '/personnel', component: FormPersonnel, exact: true, meta: { breadcrumb: [{ parent: 'Gestion RH', label: 'Gestion du personnel' }] } },
        { path: '/emprunt', component: FormEmprunt, exact: true, meta: { breadcrumb: [{ parent: 'Gestion RH', label: 'Gestion emprunt' }] } },
        { path: '/absence', component: FormAbsence, exact: true, meta: { breadcrumb: [{ parent: 'Opération', label: 'Gestion des absences' }] } },
        { path: '/document', component: FormDocument, exact: true, meta: { breadcrumb: [{ parent: 'Opération', label: 'Gestion des documents' }] } },
        { path: '/emploi', component: FormEmploiTemps, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Emploi de temps' }] } },
        { path: '/mission', component: FormMission, exact: true, meta: { breadcrumb: [{ parent: 'Paramétrage', label: 'Missions' }] } },
        { path: '/paie', component: FormPaie, exact: true, meta: { breadcrumb: [{ parent: 'Gestion RH', label: 'Gestion paie' }] } },
        { path: '/note/:typeOp', component: FormSaisieNote, exact: true, meta: { breadcrumb: [{ parent: 'Gestion note', label: 'Gestion note' }] } },
        { path: '/stat_cours_classe', component: StatCoursClasse, exact: true, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Statistiques des cours par classe' }] } },
        { path: '/stat_cours_matiere', component: StatCoursMatiere, exact: true, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Statistiques des cours par matière' }] } },
        { path: '/stat_depense', component: StatGnleDep, exact: true, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Statistiques des dépenses' }] } },
        { path: '/stat_effectif', component: StatEffectif, exact: true, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Statistiques des effectifs' }] } },
        { path: '/stat_resultat', component: StatResultat, exact: true, meta: { breadcrumb: [{ parent: 'Statistiques', label: 'Statistiques des résultats' }] } },
        { path: '/assignation', component: EditionAssignation, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Edition assignation' }] } },
        { path: '/traces', component: EditionJournalTrace, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Journal des traces' }] } },
        { path: '/ed_emploi', component: EditionEmploiTemps, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Emploi de temps' }] } },
        { path: '/recus', component: EditionReçus, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Edition reçus' }] } },
        { path: '/ed_encaissement', component: EditionEncaissement, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Encaissements' }] } },
        { path: '/situation_vers', component: EditionSituationFrais, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Situation frais' }] } },
        { path: '/etat_paie', component: EditionEtatPaie, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Etat de paie' }] } },
        { path: '/ed_absence', component: EditionAbsence, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Edition absence' }] } },
        { path: '/ed_personnel', component: EditionPersonnelDocs, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Personnels et docs' }] } },
        { path: '/bilan', component: EditionBilanAnnuel, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Bilan annuel' }] } },
        { path: '/dossier_scolaire', component: EditionEtudiantDoc, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Dossiers scolaires' }] } },
        { path: '/resultats', component: EditionResultats, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Edition résultats' }] } },
        { path: '/notes', component: EditionNotes, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Edition notes' }] } },
        { path: '/questionnaire', component: FormQuestionnaire, exact: true, meta: { breadcrumb: [{ parent: 'Sondage', label: 'Questionnaires' }] } },
        { path: '/planing', component: FormPlanning, exact: true, meta: { breadcrumb: [{ parent: 'Sondage', label: 'Planification sondage' }] } },
        { path: '/sondage/:typeOp', component: FormEvalSondage, exact: true, meta: { breadcrumb: [{ parent: 'Sondage', label: 'Sondage' }] } },
        { path: '/evenement', component: FormEvent, exact: true, meta: { breadcrumb: [{ parent: 'Messagerie', label: 'Evènement' }] } },
        { path: '/messagerie', component: FormMessage, exact: true, meta: { breadcrumb: [{ parent: 'Messagerie', label: 'Messagerie' }] } },
        { path: '/theme', component: FormTheme, exact: true, meta: { breadcrumb: [{ parent: 'Messagerie', label: 'Gestion thèmes' }] } },
        { path: '/resultat_sondage', component: EditionResultatSondage, exact: true, meta: { breadcrumb: [{ parent: 'Editions', label: 'Edition sondage' }] } },

    ];

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        if (staticMenuMobileActive) {
            blockBodyScroll();
        }
        else {
            unblockBodyScroll();
        }
    }, [staticMenuMobileActive]);

    useEffect(() => {
        changeStyleSheetUrl('layout-css', 'layout-' + colorScheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + colorScheme + '.css', 1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    };

    const changeMenuTheme = (name, logoColor, componentTheme) => {
        onMenuThemeChange(name);
        changeStyleSheetUrl('theme-css', componentTheme, 2);
        setComponentTheme(componentTheme)

        const appLogoLink = document.getElementById('app-logo');
        const appLogoUrl = `assets/layout/images/logo-${logoColor === 'dark' ? 'dark' : 'white'}.png`;
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        const horizontalLogoUrl = `assets/layout/images/logo-${logoColor === 'dark' ? 'dark' : 'white'}.png`;

        if (appLogoLink) {
            appLogoLink.src = appLogoUrl;
        }
        if (horizontalLogoLink) {
            horizontalLogoLink.src = horizontalLogoUrl;
        }
        setLogoColor(logoColor);
    };

    const changeComponentTheme = (theme) => {
        setComponentTheme(theme)
        changeStyleSheetUrl('theme-css', theme, 3);
    };

    const changeColorScheme = (e) => {
        setColorScheme(e.value);

        const scheme = e.value;
        changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
        changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);

        changeLogo(scheme);
    };

    const changeStyleSheetUrl = (id, value, from) => {
        const element = document.getElementById(id);
        const urlTokens = element.getAttribute('href').split('/');

        if (from === 1) {
            // which function invoked this function
            urlTokens[urlTokens.length - 1] = value;
        } else if (from === 2) {
            // which function invoked this function
            if (value !== null) {
                urlTokens[urlTokens.length - 2] = value;
            }
        } else if (from === 3) {
            // which function invoked this function
            urlTokens[urlTokens.length - 2] = value;
        }

        const newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    };

    const changeLogo = (scheme) => {
        const appLogoLink = document.getElementById("app-logo");
        const mobileLogoLink = document.getElementById("logo-mobile");
        const invoiceLogoLink = document.getElementById("invoice-logo");
        const footerLogoLink = document.getElementById("footer-logo");
        const horizontalLogoLink = document.getElementById('logo-horizontal');
        setLogoUrl(`assets/layout/images/logo-${scheme === 'light' ? 'dark' : 'white'}.png`);

        if (appLogoLink) {
            appLogoLink.src = `assets/layout/images/logo-${scheme === 'light' ? logoColor : 'white'}.png`;
        }

        if (horizontalLogoLink) {
            horizontalLogoLink.src = `assets/layout/images/logo-${scheme === 'light' ? logoColor : 'white'}.png`;
        }

        if (mobileLogoLink) {
            mobileLogoLink.src = logoUrl;
        }

        if (invoiceLogoLink) {
            invoiceLogoLink.src = logoUrl;
        }

        if (footerLogoLink) {
            footerLogoLink.src = logoUrl;
        }
    };

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute("href", href);
        }
        else {
            const id = linkElement.getAttribute("id");
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute("href", href);
            cloneLinkElement.setAttribute("id", id + "-clone");

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener("load", () => {
                linkElement.remove();
                cloneLinkElement.setAttribute("id", id);
            });
        }
    };


    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
    };


    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!userMenuClick) {
            setTopbarUserMenuActive(false);
        }

        if (!notificationMenuClick) {
            setTopbarNotificationMenuActive(false);
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                hideOverlayMenu();
            }

            unblockBodyScroll();
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        searchClick = false;
        configClick = false;
        userMenuClick = false;
        rightMenuClick = false;
        notificationMenuClick = false;
        menuClick = false;
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setTopbarNotificationMenuActive(false);
        setRightMenuActive(false);

        if (isOverlay()) {
            setOverlayMenuActive(prevOverlayMenuActive => !prevOverlayMenuActive);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevStaticMenuDesktopInactive => !prevStaticMenuDesktopInactive);
        }
        else {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
        }

        event.preventDefault();
    };

    const onMenuitemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();

            if (isSlim() || isHorizontal()) {
                setMenuActive(false);
            }
        }
    };

    const onRootMenuitemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };

    const onMenuThemeChange = (name) => {
        setMenuTheme('layout-sidebar-' + name);
    };

    const onMenuModeChange = (e) => {
        setMenuMode(e.value);
        if (e.value === 'static') {
            setStaticMenuDesktopInactive(false)
        }
    };

    const onTopbarUserMenuButtonClick = (event) => {
        userMenuClick = true;
        setTopbarUserMenuActive(prevTopbarUserMenuActive => !prevTopbarUserMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const onTopbarNotificationMenuButtonClick = (event) => {
        notificationMenuClick = true;
        setTopbarNotificationMenuActive(prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive);

        hideOverlayMenu();

        event.preventDefault();
    };

    const toggleSearch = () => {
        setSearchActive(prevSearchActive => !prevSearchActive);
        searchClick = true;
    };

    const onSearchClick = () => {
        searchClick = true;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuButtonClick = (event) => {
        rightMenuClick = true;
        setRightMenuActive(prevRightMenuActive => !prevRightMenuActive);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = () => {
        setConfigActive(prevConfigActive => !prevConfigActive);
        configClick = true;
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };

    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        }
        else {
            document.body.className += ' blocked-scroll';
        }
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        }
        else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const isSlim = () => {
        return menuMode === "slim";
    };

    const isHorizontal = () => {
        return menuMode === "horizontal";
    };

    const isOverlay = () => {
        return menuMode === "overlay";
    };

    const isDesktop = () => {
        return window.innerWidth > 1091;
    };

    const containerClassName = classNames('layout-wrapper',
        {
            'layout-overlay': menuMode === "overlay",
            'layout-static': menuMode === "static",
            'layout-slim': menuMode === "slim",
            'layout-horizontal': menuMode === "horizontal",
            'layout-sidebar-dim': colorScheme === "dim",
            'layout-sidebar-dark': colorScheme === "dark",
            'layout-overlay-active': overlayMenuActive,
            'layout-mobile-active': staticMenuMobileActive,
            'layout-static-inactive': staticMenuDesktopInactive && menuMode === "static",
            'p-input-filled': inputStyle === "filled",
            'p-ripple-disabled': !ripple,
        },
        colorScheme === 'light' ? menuTheme : '');

    return (
        <div className={containerClassName} data-theme={colorScheme} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />

            <div className="layout-content-wrapper">
                <AppTopbar routers={routers} topbarNotificationMenuActive={topbarNotificationMenuActive} topbarUserMenuActive={topbarUserMenuActive}
                    onMenuButtonClick={onMenuButtonClick} onSearchClick={toggleSearch} onTopbarNotification={onTopbarNotificationMenuButtonClick}
                    onTopbarUserMenu={onTopbarUserMenuButtonClick} onRightMenuClick={onRightMenuButtonClick} onRightMenuButtonClick={onRightMenuButtonClick}
                    menu={menu} menuMode={menuMode} menuActive={menuActive} staticMenuMobileActive={staticMenuMobileActive} onMenuClick={onMenuClick}
                    onMenuitemClick={onMenuitemClick} onRootMenuitemClick={onRootMenuitemClick}></AppTopbar>

                <div className="layout-content">
                    <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                        <AppBreadcrumb routers={routers} />
                    </div>

                    {
                        routers.map((router, index) => {
                            if (router.exact) {
                                return <Route key={`router${index}`} path={router.path} exact component={router.component} render={router.render} />
                            }

                            return <Route key={`router${index}`} path={router.path} component={router.component} render={router.render} />
                        })
                    }
                </div>

                <AppFooter />
            </div>

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick}></AppRightMenu>

            <AppConfig configActive={configActive} menuMode={menuMode} onMenuModeChange={onMenuModeChange} colorScheme={colorScheme}
                changeColorScheme={changeColorScheme} menuTheme={menuTheme} changeMenuTheme={changeMenuTheme}
                componentTheme={componentTheme} changeComponentTheme={changeComponentTheme} onConfigClick={onConfigClick} onConfigButtonClick={onConfigButtonClick}
                rippleActive={ripple} onRippleChange={onRippleChange} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}></AppConfig>

            <AppSearch searchActive={searchActive} onSearchClick={onSearchClick} onSearchHide={onSearchHide} />
        </div>
    );
}

export default App;
