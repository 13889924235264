import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import GestGemsysService from '../service/GestGemsysService';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import './styles.css';
import { Panel } from 'primereact/panel';

export class FormFonction extends Component {
    constructor() {
        super();
        this.state = {
            numFonc: '',
            nomFonc: '',
            denregFct: new Date(),
            dateDebutFct: '',
            dateFinFct: '',
            salaire: 0,
            actExe: 'Oui',
            idEtabl: 1,
            iduser: '1',

            idExe: '',
            anneeUniv: new Date().getFullYear().toString(),
            denregExercer: new Date(),
            matrPers: '',
            liste_exercers: [],
            liste_fonctions: [],
            liste_personnel: [],
            selectedFonction: null,
            selectedExercer: null,
            msg: '',
            modif: false,
            modif_exercer: false,
            visible: false,
            visible_exercer: false,
            userconnected: {},
            showindicator: false
            //userconnected: JSON.parse(localStorage.getItem('userconnected'))
        };
        this.edit_exercer = this.edit_exercer.bind(this);
        this.conf_delete_exercer = this.conf_delete_exercer.bind(this);
        this.gemsysservice = new GestGemsysService();

    }
    componentDidMount() {
        this.get_liste_fonctions();
        this.get_full_personnel()
    }
    get_full_personnel() {
        this.gemsysservice.getPersonnel().then(data => this.setState({ liste_personnel: data }));
    }
    get_liste_fonctions() {
        this.setState({ showindicator: true });
        this.gemsysservice.getFonction().then(data => this.setState({ liste_fonctions: data, showindicator: false }));
    }
    get_liste_exercer_by_numFonction(numFonc) {
        if (numFonc != null && numFonc != undefined) {
            this.gemsysservice.getExercerByNumFonction(numFonc).then(data => this.setState({ liste_exercers: data }));
        }
    }
    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_fonction();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }
    resultat_exercer(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.get_liste_exercer_by_numFonction(this.state.selectedFonction.numFonc)
            this.annule_exercer();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }
    edit_fonction(data) {
        if (data != null && data != undefined) {
            this.setState({ numFonc: data.numFonc });
            this.setState({ nomFonc: data.nomFonc });
            this.setState({ modif: true });
            this.setState({ selectedFonction: data });
        }
    }

    annule_fonction() {
        this.setState({ numFonc: '' });
        this.setState({ nomFonc: '' });
        this.setState({ idEtabl: '' });
        this.setState({ iduser: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_fonction() {
        if (this.state.nomFonc == "" || this.state.nomFonc == null || this.state.nomFonc == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            if (this.state.modif == false) {
                let fonction = {
                    numFonc: 0,
                    nomFonc: this.state.nomFonc
                }
                fonction.numFonc = "";
                this.gemsysservice.AddFonction(fonction).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                    }
                });
            } else {
                let fonction = this.state.selectedFonction;
                fonction.nomFonc = this.state.nomFonc;
                this.gemsysservice.UpdateFonction(fonction).then(data => {
                    if (data != undefined && data != null) {
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }
    }

    edit_exercer(data) {
        if (data != null && data != undefined) {
            this.setState({ idExe: data.idExe });
            this.setState({ dateDebutFct: new Date(moment(data.dateDebutFct, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ dateFinFct: new Date(moment(data.dateFinFct, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ anneeUniv: data.anneeUniv });
            this.setState({ salaire: data.salaire });
            this.setState({ matrPers: data.matrPers.matrPers });
            this.setState({ numFonc: '0' });
            this.setState({ actExe: 'Oui' });
            this.setState({ modif_exercer: true });
            this.setState({ selectedExercer: data });
            window.scrollTo(0, 0);
        }
    }

    annule_exercer() {
        this.setState({ idExe: '' });
        this.setState({ anneeUniv: this.state.anneeUniv });
        this.setState({ salaire: 0 });
        this.setState({ matrPers: null });
        this.setState({ numFonc: null });
        this.setState({ actExe: 'Oui' });
        this.setState({ iduser: '1' });
        this.setState({ modif_exercer: false });
        this.forceUpdate();
    }

    enreg_exercer() {
        if (this.state.selectedFonction == null || this.state.matrPers == "" || this.state.dateDebutFct == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({ showindicator: true });
            let date_fin = moment(this.state.dateDebutFct, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (date_fin == 'Invalid date') {
                date_fin = null;
            }
            if (this.state.modif_exercer == false) {
                let exercer = {
                    idExe: '0',
                    dateDebutFct: moment(this.state.dateDebutFct, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    dateFinFct: date_fin,
                    anneeUniv: this.gemsysservice.get_rannee(),
                    salaire: this.state.salaire,
                    matrPers: this.state.matrPers,
                    numFonc: this.state.selectedFonction,
                    actExe: 'Oui'
                };
                this.gemsysservice.AddExercer(exercer).then(data => this.resultat_exercer(data.code, data.contenu));
            } else {
                let exercer = this.state.selectedExercer;
                exercer.dateDebutFct = moment(this.state.dateDebutFct, 'DD/MM/YYYY').format('DD/MM/YYYY');
                exercer.dateFinFct = date_fin;
                exercer.salaire = this.state.salaire;
                exercer.matrPers = { matrPers: this.state.matrPers };
                exercer.numFonc = this.state.selectedFonction;
                this.gemsysservice.UpdateExercer(exercer).then(data => this.resultat_exercer(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_exercer(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_exercer(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    actionTemplate_fonction(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button onClick={() => this.edit_fonction(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button onClick={() => this.conf_delete_fonction(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_exercer() {
        if (this.state.selectedExercer == null || this.state.selectedExercer == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysservice.DeleteExercer(this.state.selectedExercer).then(data => this.resultat_exercer(data.code, data.contenu));
        }
    }
    conf_delete_exercer(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedExercer: data });
            this.setState({ msg: data.idExe });
            this.setState({ visible_exercer: true });
        }
    }
    delete_fonction() {
        if (this.state.selectedFonction == null || this.state.selectedFonction == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.gemsysservice.DeleteFonction(this.state.selectedFonction).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_fonction(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedFonction: data });
            this.setState({ msg: data.numFonc });
            this.setState({ visible: true });
        }
    }

    render() {
        let personnels = null;
        if (this.state.liste_personnel != null && this.state.liste_personnel != undefined) {
            personnels = this.state.liste_personnel.map(x => {
                return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers, categoriePers: x.categorie }
            });
        }

        const dialogFooter_fonction = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_fonction();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        const dialogFooter_exercer = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible_exercer: false });
                    this.delete_exercer();
                }} label="Oui" loading={this.state.showindicator} />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible_exercer: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter_fonction} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog header="Confirmation" visible={this.state.visible_exercer} style={{ width: '250px' }} modal={true} footer={dialogFooter_exercer} onHide={() => this.setState({ visible_exercer: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-sitemap"></i> Gestion des fonctions<hr />
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <Panel style={{ minHeight: '200px' }}>
                                        <div className="grid">
                                            <div className="col-12 md:col-6">
                                                <label htmlFor="nomFonc">Libelle *</label>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <InputText id="nomFonc" value={this.state.nomFonc} onChange={(e) => this.setState({ nomFonc: e.target.value })} />
                                            </div>
                                        </div>
                                        <center>
                                            <div className="grid" style={{ width: 300 }}>
                                                <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_fonction()} /></div>
                                                <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_fonction()} /></div>
                                            </div>
                                        </center>
                                    </Panel>
                                </div>
                                <div className="col-12 md:col-6">
                                    <Panel style={{ minHeight: '200px' }}>
                                        <div className="grid">
                                            <div className="col-12">
                                                <DataTable value={this.state.liste_fonctions}
                                                    selectionMode="single" alwaysShowPaginator={false}
                                                    onRowClick={e => this.setState({ selectedFonction: e.data })} onRowSelect={e =>
                                                        this.setState({ selectedFonction: e.data }, () => {
                                                            this.get_liste_exercer_by_numFonction(e.data.numFonc);
                                                        })
                                                    } selection={this.state.selectedFonction} dataKey="numFonc" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                                    <Column header="#" body={this.actionTemplate_fonction.bind(this)} style={{ width: '15%' }} />
                                                    <Column field="nomFonc" header="Nom Fonction" style={{ width: '85%' }} />
                                                </DataTable>
                                            </div>
                                        </div>
                                    </Panel>
                                </div>

                                <i className="pi pi-sitemap"></i> Gestion des affectations<hr />
                                <hr />

                                    <div className="col-12">
                                        <DataTable value={this.state.liste_exercers} style={{ minHeight: '200px' }}
                                            selectionMode="single" onRowClick={e => this.setState({ selectedExercer: e.data })}
                                            selection={this.state.selectedExercer}
                                            dataKey="idFct" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                            <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '8%' }} />
                                            <Column field="matrPers.matrPers" header="Matricule" style={{ width: '10%' }} />
                                            <Column header="Personnel" body={(rowData, column) => {
                                                return <div>
                                                    <span style={{ color: '#000000' }}>{rowData.matrPers.prenomPers + " " + rowData.matrPers.nomPers}</span>
                                                </div>
                                            }} />
                                            <Column field="dateDebutFct" header="Date début" style={{ width: '15%' }} />
                                            <Column field="dateFinFct" header="Date fin" style={{ width: '15%' }} />
                                            <Column field="salaire" header="Salaire" body={(rowData, column) => {
                                                return <div>
                                                    <span style={{ color: 'green' }}>{this.gemsysservice.formaterValueSep(parseFloat(rowData.salaire))}</span>
                                                </div>
                                            }} style={{ width: '15%' }} />
                                            <Column field="actExe" header="Actif" style={{ width: '8%' }} />
                                        </DataTable>
                                    </div>
                                    <Panel>
                                        <div className="grid">
                                            <div className="col-12 md:col-3">
                                                <label htmlFor="matrPers">Personnel *</label>
                                            </div>
                                            <div className="col-12 md:col-3" >
                                                <Dropdown options={personnels} value={this.state.matrPers} onChange={(e) => this.setState({ matrPers: e.target.value })}
                                                    itemTemplate={(option) => {
                                                        if (!option.value) {
                                                            return option.label;
                                                        }
                                                        else {
                                                            return (
                                                                <div className="grid" >
                                                                    <div className="col-12 md:col-2">
                                                                        <label>{option.value}</label>
                                                                    </div>
                                                                    <div className="col-12 md:col-7">
                                                                        <label>{option.label}</label>
                                                                    </div>
                                                                    <div className="col-12 md:col-3">
                                                                        <label>{option.categoriePers}</label>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }} autoWidth={false} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' panelStyle={{ width: '500px' }} />
                                            </div>
                                            <div className="col-12 md:col-3">
                                                <label htmlFor="salaire">Salaire *</label>
                                            </div>
                                            <div className="col-12 md:col-3" >
                                                <InputNumber value={this.state.salaire} onChange={(e) => this.setState({ salaire: e.value })} />
                                            </div>
                                            <div className="col-12 md:col-3">
                                                <label htmlFor="dateDebutFct">Date début *</label>
                                            </div>
                                            <div className="col-12 md:col-3" >
                                                <Calendar id="dateDebutFct" dateFormat="dd/mm/yy" value={this.state.dateDebutFct} onChange={(e) => this.setState({ dateDebutFct: e.target.value })}></Calendar>
                                            </div>
                                            <div className="col-12 md:col-3">
                                                <label htmlFor="dateFinFct">Date fin *</label>
                                            </div>
                                            <div className="col-12 md:col-3">
                                                <Calendar id="dateFinFct" dateFormat="dd/mm/yy" value={this.state.dateFinFct} onChange={(e) => this.setState({ dateFinFct: e.target.value })}></Calendar>
                                            </div>

                                        </div>
                                        <center>
                                        <div className="grid" style={{ width: 300, marginTop: '0%' }}>
                                            <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_exercer()} /></div>
                                            <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_exercer()} /></div>
                                        </div>
                                    </center>
                                    </Panel>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
