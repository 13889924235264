import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { Toast } from 'primereact/toast';

export class EditionEtudiantDoc extends Component {
    constructor() {
        super();
        this.state = {
            items: [
                {
                    label: 'Liste formatée',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_liste('PDF', 'LISTE');
                    },
                    typeEtabl: 'ALL',
                    profil: 'ALL'
                },
                {
                    label: 'Liste brute',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_liste('XLS', 'BRUTE');
                    },
                    typeEtabl: 'ALL',
                    profil: 'ALL'
                },
                {
                    label: 'Liste avec photo',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_liste('PDF', 'PHOTO');
                    },
                    typeEtabl: 'ALL',
                    profil: 'ALL'
                },
                {
                    label: 'Diplomes',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_diplome('ORIGINAL');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Duplicata diplome',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_diplome('DUPLICATA');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Badges',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_selection('BADGE');
                    },
                    typeEtabl: 'ALL',
                    profil: 'ALL'
                },
                {
                    label: 'Bulettin notes PDF',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_bulletin('PDF', 'BULLETIN');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Bulettin notes XLS',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_bulletin('XLS', 'BULLETIN');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Relevés notes PDF',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_releve_note('PDF', 'RELEVE_NOTE');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Relevés notes XLS',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_releve_note('XLS', 'RELEVE_NOTE');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Fiche de paie',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_selection('FICHE_PAIE');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité_Comptable'
                },
                {
                    label: 'Etat de paie',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_liste('PDF', 'ETAT_PAIE');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité_Comptable'
                },
                {
                    label: "Fiche d'inscription",
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_selection('FICHE_INSC');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Résultats classe',
                    icon: 'pi pi-print',
                    command: () => {
                    },
                    typeEtabl: 'Pré-universitaire',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Relevés des moyennes',
                    icon: 'pi pi-print',
                    command: () => {
                    },
                    typeEtabl: 'Université',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: "Attestation (ré)inscription",
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_selection('ATTI');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Attestation niveau',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_attestation_niveau('RES_FINAL');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_DG_DGE_Chargé scolarité'
                },
                {
                    label: 'Certificat scolarité',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print_selection('CSCOL');
                    },
                    typeEtabl: 'Université_Professionnel',
                    profil: 'Administrateur_Chargé scolarité'
                }
            ],
            rcode_classe: 'Tous',
            liste_data: [],
            selectedListes: [],
            showindicator: false,
            rcode_enseign: 'Tous',
            titredoc: 'Liste générale',
            rstac: 'Tous',
            rstaf: 'Tous',
            rsexe: 'Tous',
            rsituation: 'Tous',
            rgroupe: 'Tous',
            rval: 'Tous',
            liste_classe: [],
            liste_statut_accademique: [{ label: 'Statut accadémique', value: 'Tous' }],
            liste_statut_financier: [{ label: 'Statut financier', value: 'Tous' }],
            liste_enseignement: [],


        };
        this.conf_print_liste = this.conf_print_liste.bind(this);
        this.conf_print_selection = this.conf_print_selection.bind(this);
        this.conf_print_bulletin = this.conf_print_bulletin.bind(this);
        this.conf_print_releve_note = this.conf_print_releve_note.bind(this);
        this.conf_print_attestation_niveau= this.conf_print_attestation_niveau.bind(this);
        this.conf_print_diplome=this.conf_print_diplome.bind(this);
        this.gestGemsysService = new GestGemsysService();
    }

    conf_print_liste(format, doc) {
        if (doc == 'LISTE') {
            this.gestGemsysService.generer_liste_eleve(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        } else if (doc == 'PHOTO') {
            this.gestGemsysService.generer_liste_eleve_photo(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        } else if (doc == 'BRUTE') {
            this.gestGemsysService.generer_liste_eleve_brute(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        } else if (doc == 'ETAT_PAIE') {
            this.gestGemsysService.generer_etat_paie_boursier(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe, this.state.titredoc, format);
        }
    }

    conf_print_bulletin(format, lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_bulletin_note(ids, lib, this.state.rcode_classe, format);
        }
    }

    conf_print_diplome(lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_diplome(ids, lib, this.state.rcode_classe);
        }
    }

    conf_print_attestation_niveau(lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_attestation_niveau(ids, lib, this.state.rcode_classe);
        }
    }

    conf_print_releve_note(format, lib) {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            let ids = "";
            if (this.state.selectedListes.length > 0) {
                ids = this.state.selectedListes.map(x => {
                    return x.idInsc
                }).join("_");
            } else {
                ids = this.state.liste_data.map(x => {
                    return x.idInsc
                }).join("_");
            }
            this.gestGemsysService.generer_releve_note(ids, lib, this.state.rcode_classe, format);
        }
    }



    conf_print_selection(doc) {
        let ids = "";
        if (this.state.selectedListes.length > 0) {
            ids = this.state.selectedListes.map(x => {
                return x.idInsc
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.idInsc
            }).join("_");
        }
        if (doc == 'BADGE') {
            this.gestGemsysService.generer_badge_eleve(ids);
        } else if (doc == 'FICHE_PAIE') {
            this.gestGemsysService.generer_fiche_paie(ids);
        } else if (doc == 'ATTI') {
            this.gestGemsysService.generer_attestation_insc(ids);
        } else if (doc == 'CSCOL') {
            this.gestGemsysService.generer_certificat_scol(ids);
        } else if (doc == 'FICHE_INSC') {
            this.gestGemsysService.generer_fiche_insc(ids);
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gestGemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    get_liste_classe(codeEnseign) {
        this.gestGemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    let lc=this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université'?'S':'';
                    return { value: x.codeClasse, label: lc+x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign }
                }).filter(function (el) {
                    return el.codeEnseign == codeEnseign;
                });
                this.setState({ liste_classe: this.state.liste_classe.concat(liste) });
            }
        });
    }

    remove_duplicates(arr) {
        let s = new Set(arr);
        let it = s.values();
        return Array.from(it);
    }

    get_liste_statut() {
        this.gestGemsysService.getStatut().then(data => {
            if (data !== null && data !== undefined) {
                let liste_f = this.remove_duplicates(data.map(x => { return x.statutFinancier })).map(x => { return { value: x, label: x } });
                let liste_a = this.remove_duplicates(data.map(x => { return x.statutAccademique })).map(x => { return { value: x, label: x } });
                this.setState({
                    liste_statut_accademique: this.state.liste_statut_accademique.concat(liste_a),
                    liste_statut_financier: this.state.liste_statut_financier.concat(liste_f)
                });
            }
        });
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gestGemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }
 
    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_statut();
    }

    get_liste_data() {
        this.setState({ showindicator: true });
        this.gestGemsysService.get_liste_eleve_edition(this.state.rcode_enseign, this.state.rcode_classe, this.state.rsexe, this.state.rstac, this.state.rstaf, this.state.rval, this.state.rsituation, this.state.rgroupe).then(data => {
            this.setState({
                liste_data: data,
                showindicator: false
            });
        });
    }
    render() {
        let liste_grs = [
            { label: 'Groupe sanguin', value: 'Tous' },
            { label: 'A', value: 'A' },
            { label: 'B', value: 'B' },
            { label: 'AB', value: 'AB' },
            { label: 'AB+', value: 'AB+' },
            { label: 'AB-', value: 'AB-' },
            { label: 'B+', value: 'B+' },
            { label: 'A+', value: 'A+' },
            { label: 'B-', value: 'B-' },
            { label: 'O+', value: 'O+' },
            { label: 'O-', value: 'O-' }
        ];
        let Liste_sexe = [
            { label: 'Sexe', value: 'Tous' },
            { label: 'Masculin', value: 'M' },
            { label: 'Féminin', value: 'F' }
        ];
        let liste_situation = [
            { label: 'Situation maritale', value: 'Tous' },
            { label: 'Célibataire', value: 'Célibataire' },
            { label: 'Marié(e)', value: 'Marié(e)' },
            { label: 'Divorcé(e)', value: 'Divorcé(e)' },
            { label: 'Veuve', value: 'Veuve' }
        ];

        let liste_val = [
            { label: 'Statut inscription', value: 'Tous' },
            { label: 'Validé', value: '1' },
            { label: 'Non validé', value: '0' },
            { label: 'Annulé', value: '2' },
        ]

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid"> 
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Dropdown options={[{ label: this.gestGemsysService.get_type_etabl()=='Université'?"Département":"Enseignement", value: 'Tous' }].concat(this.state.liste_enseignement)} placeholder={this.gestGemsysService.get_type_etabl()=='Université'?"Département":"Enseignement"} value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value }, () => {
                                        this.get_liste_classe(this.state.rcode_enseign);
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_classe} placeholder="Classe" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value },()=>{
                                        this.get_liste_data();
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={Liste_sexe} value={this.state.rsexe} onChange={(e) => this.setState({ rsexe: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_situation} placeholder="Sélectionner" value={this.state.rsituation} onChange={(e) => this.setState({ rsituation: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_statut_accademique} value={this.state.rstac} onChange={(e) => this.setState({ rstac: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_statut_financier} placeholder="Statut financier" value={this.state.rstaf} onChange={(e) => this.setState({ rstaf: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_grs} placeholder="Groupe sanguin" value={this.state.rgroupe} onChange={(e) => this.setState({ rgroupe: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_val} value={this.state.rval} onChange={(e) => this.setState({ rval: e.value })} autoWidth={false} />
                                </div>
                            </div>
                            <Panel style={{ marginTop: '1%' }}>
                                <div className="grid">
                                    <div className="col-12 md:col-3">
                                        <label>Titre du document</label>
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <InputText id="titredoc" value={this.state.titredoc} onChange={(e) => this.setState({ titredoc: e.target.value })} />
                                    </div>
                                    <div className="col-12 md:col-3">
                                        <SplitButton label="Rechercher" onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                    </div>
                                    <div className="col-12 md:col-2">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </Panel>

                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}
                                paginator={true} rows={20} alwaysShowPaginator={true} paginatorPosition="top"  
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll"
                                onSelectionChange={e => this.setState({ selectedListes: e.value })} selection={this.state.selectedListes} >
                                <Column selectionMode="multiple" style={{ width: '4%' }} />
                                <Column field="ordre" header="#" style={{ width: '4%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matrEtud" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="nomEtud" header="Nom" style={{ width: '10%' }} filter={true} filterMatchMode='contains' />
                                <Column field="prenomEtu" header="Prénoms" style={{ width: '20%' }} filter={true} filterMatchMode='contains' />
                                <Column field="sexe" header="Sexe" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                                <Column field="dateNaiss" header="Date_Nais" style={{ width: '9%' }} filter={true} filterMatchMode='contains' />
                                <Column field="groupesanguin" header="GRS" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                                <Column field="telEtud" header="Télephone" style={{ width: '9%' }} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}