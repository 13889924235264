import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
export class EditionNotes extends Component {
    constructor() {
        super();
        this.state = {
            rcode_classe: 'Tous',
            rcodemat: 'Tous',
            rstatut: 'Tous',
            module: 'Module 1',
            liste_data: [],
            liste_classe: [{ label: 'Classe', value: 'Tous' }],
            liste_matiere: [{ label: 'Matiere', value: 'Tous' }],
            showindicator: false,
            items: [
                {
                    label: 'Fiche de notes',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print.bind('FICHE');
                    }
                },
                {
                    label: 'Fiche de notes vierge',
                    icon: 'pi pi-print',
                    command: () => {
                        this.conf_print.bind('VIERGE');
                    }
                },
                {
                    label: 'Notification',
                    icon: 'pi pi-bell',
                    command: () => {
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }



    get_liste_classe() {
        this.setState({ showindicator: true });
        this.gemsysService.getClasse().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
                    });
                    this.setState({ liste_classe: this.state.liste_classe.concat(liste) });
                }
            }
        });
    }

    get_liste_matiere_by_classe(codeClasse) {
        this.gemsysService.getMatiere_by_classe(codeClasse).then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.codemat, label: x.libmat }
                });
                this.setState({ liste_matiere: liste });
            }
        });
    }

    get_liste_data() {
        if (this.state.rcodemat == "" || this.state.rcodemat == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            this.setState({
                showindicator: true,
            });
            this.gemsysService.get_liste_notes(this.state.rcodemat, this.state.rstatut, this.state.rcode_classe, this.state.module).then(data => {
                this.setState({ liste_data: data, showindicator: false });
            });
        }
    }

    conf_print(doc) {
        if (this.state.rcodemat == "" || this.state.rcodemat == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            if (doc == 'VIERGE') {
                this.gemsysService.generer_fiche_notes_vierge(this.state.rcodemat, this.state.rcode_classe, this.state.module);
            } else {
                this.gemsysService.generer_fiche_notes(this.state.rcodemat, this.state.rstatut, this.state.rcode_classe, this.state.module);
            }
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }


    get_tab() {
        if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            return <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}
                dataKey="matrEtud" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300} >
                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                <Column field="matrEtud" header="Matricule" style={{ width: '10%' }} />
                <Column field="nomEtud" header="Nom" style={{ width: '10%' }} />
                <Column field="prenomEtu" header="Prénoms" style={{ width: '24%' }} />
                <Column field="note_cours" header="Note cours" style={{ width: '12%' }} />
                <Column field="note_compo" header="Note compo" style={{ width: '12%' }} />
                <Column field="moyc" header="MoyC" style={{ width: '12%' }} />
                <Column field="ap" header="Apréciation" style={{ width: '10%' }} />
            </DataTable>
        } else if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            return <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}
                dataKey="matrEtud" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                {/* <Column field="" header="#" style={{ width: '5%' }} /> */}
                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                <Column field="matrEtud" header="Matricule" style={{ width: '10%' }} />
                <Column field="nomEtud" header="Nom" style={{ width: '10%' }} />
                <Column field="prenomEtu" header="Prénoms" style={{ width: '13%' }} />
                <Column field="note_orale1" header="NO1" style={{ width: '7%' }} />
                <Column field="note_ecrite1" header="NE1" style={{ width: '7%' }} />
                <Column field="note_orale2" header="NO2" style={{ width: '7%' }} />
                <Column field="note_ecrite2" header="NE2" style={{ width: '7%' }} />
                <Column field="note_orale3" header="NO3" style={{ width: '7%' }} />
                <Column field="note_ecrite3" header="NE3" style={{ width: '7%' }} />
                <Column field="moyc" header="Moy" style={{ width: '7%' }} />
                <Column field="ap" header="Apréciation" style={{ width: '8%' }} />
            </DataTable>
        } else {
            return <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}
                dataKey="matrEtud" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                {/* <Column field="" header="#" style={{ width: '5%' }} /> */}
                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                <Column field="matrEtud" header="Matricule" style={{ width: '10%' }} />
                <Column field="nomEtud" header="Nom" style={{ width: '10%' }} />
                <Column field="prenomEtu" header="Prénoms" style={{ width: '20%' }} />
                <Column field="note1" header="Note1" style={{ width: '8%' }} />
                <Column field="note2" header="Note2" style={{ width: '8%' }} />
                <Column field="note3" header="Note3" style={{ width: '8%' }} />
                <Column field="moyc" header="MoyC" style={{ width: '8%' }} />
                <Column field="moyl" header="MoyL" style={{ width: '8%' }} />
                <Column field="ap" header="Apréciation" style={{ width: '10%' }} />
            </DataTable>
        }

    }

    render() {

        let liste_statut = [
            { value: 'Tous', label: 'Statut' },
            { value: 'Admis', label: 'Admis' },
            { value: 'Echec', label: 'Echec' },
        ]
        let liste_module = [];
        if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            liste_module = [
                { value: 'Tous', label: 'Module' },
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
            ];
        } else if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            liste_module = [
                { value: 'Tous', label: 'Module' },
                { value: 'Octobre', label: 'Octobre' },
                { value: 'Novembre', label: 'Novembre' },
                { value: 'Decembre', label: 'Décembre' },
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Janvier', label: 'Janvier' },
                { value: 'Fevrier', label: 'Fevrier' },
                { value: 'Mars', label: 'Mars' },
                { value: 'Module 2', label: 'Module 2' },
                { value: 'Avril', label: 'Avril' },
                { value: 'Mai', label: 'Mai' },
                { value: 'Juin', label: 'Juin' },
                { value: 'Module 3', label: 'Module 3' }
            ];
        } else {
            liste_module = [
                { value: 'Tous', label: 'Module' },
                { value: 'Les admis', label: 'Admis' },
                { value: 'Les sessionnaires', label: 'Session' },
                { value: 'Les repries', label: 'Reprise' }
            ];
        }
        return (
            <div className="p-fluid">
                 <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <Dropdown options={this.state.liste_classe} filter={true} value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value }, () => {
                                        this.get_liste_matiere_by_classe(this.state.rcode_classe);
                                    })} autoWidth={false} optionLabel='label'  filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_matiere} filter={true} value={this.state.rcodemat} onChange={(e) => this.setState({ rcodemat: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={liste_module} value={this.state.module} onChange={(e) => this.setState({ module: e.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.gemsysService.get_userconnected().idEtabl.typeEtabl !== 'Université' ?
                                        <Dropdown options={liste_statut} value={this.state.rstatut} onChange={(e) => this.setState({ rstatut: e.value })} autoWidth={false} />
                                        : null}
                                </div>
                                <div className="col-12 md:col-2">
                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-1">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            {this.get_tab()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}