import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import GestGemsysService from '../service/GestGemsysService';
import './styles.css';
import { Panel } from 'primereact/panel';
export class FormPaie extends Component {
    constructor() {
        super();
        this.state = {
            rmois: 'Tous',
            rlibelle: 'Tous',
            retat: 'Tous',
            rmatrPers: 'Tous',
            codePaie: '',
            mtPaie: 0,
            datePaie: new Date(),
            mtBon: 0,
            salaireB: 0,
            matrPers: null,
            numMont: 0,
            estPaye: '',
            libPaie: '',
            moisPaie: '',
            refPaie: '',
            liste_paie: [],
            liste_personnel: [],
            selectedPaie: null,
            msg: '',
            modif: false,
            visible: false,
            showindicator: false,
        };
        this.conf_payer = this.conf_payer.bind(this);
        this.conf_delete_paie = this.conf_delete_paie.bind(this);
        this.gestGemsysService = new GestGemsysService();

    }
    componentDidMount() {
        this.gestGemsysService.getPersonnel().then(data => this.setState({ liste_personnel: data }));
        this.get_liste_paies();
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_paie();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }

        this.setState({showindicator: false});
    }

    annule_paie() {
        this.setState({ codePaie: '' });
        this.setState({ mtPaie: 0 });
        this.setState({ mtBon: 0 });
        this.setState({ salaireB: 0 });
        this.setState({ matrPers: null });
        this.setState({ numMont: 0 });
        this.setState({ estPaye: '' });
        this.setState({ libPaie: '' });
        this.setState({ moisPaie: '' });
        this.setState({ refPaie: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_paie() {
        if (this.state.moisPaie == "" || this.state.libPaie == "" || this.state.estPaye == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {
            this.setState({showindicator: true});
            let paie = {
                codePaie: '0',
                mtPaie: 0,
                mtBon: 0,
                salaireB: 0,
                anneePaie: this.gestGemsysService.get_rannee(),
                matrPers: { matrPers: 0 },
                numMont: '0',
                estPaye: this.state.estPaye,
                libPaie: this.state.libPaie,
                moisPaie: this.state.moisPaie,
                refPaie: ''
            };
            this.gestGemsysService.AddPaie(paie).then(data => this.resultat(data.code, data.contenu));
        }
    }
    get_liste_paies() {
        let rmatrPers = 'Tous';
        let rlibelle = 'Tous';
        let rmois = 'Tous';
        let retat = 'Tous';
        if (this.state.rmatrPers !== undefined && this.state.rmatrPers !== null && this.state.rmatrPers !== '' && this.state.rmatrPers !== 'Tous') {
            rmatrPers = this.state.rmatrPers;
        }
        if (this.state.rmois !== undefined && this.state.rmois !== null && this.state.rmois !== '' && this.state.rmois !== 'Tous') {
            rmois = this.state.rmois;
        }
        if (this.state.rlibelle !== undefined && this.state.rlibelle !== null && this.state.rlibelle !== '' && this.state.rlibelle !== 'Tous') {
            rlibelle = this.state.rlibelle;
        }
        if (this.state.retat !== undefined && this.state.retat !== null && this.state.retat !== '' && this.state.retat !== 'Tous') {
            retat = this.state.retat;
        }
        this.setState({showindicator: true});
        this.gestGemsysService.get_liste_paies_by_criteria(rmois, rmatrPers, rlibelle, retat).then(data => this.setState({ liste_paie: data,showindicator: false }));
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button disabled={rowData.estPaye=='Oui'} onClick={() => this.conf_payer(rowData)} className="p-button-success" style={{ width: 25, height: 25 }} icon="pi pi-thumbs-up" /></div>
            <div className="col"><Button disabled={rowData.estPaye=='Oui'} onClick={() => this.conf_delete_paie(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }

    delete_paie() {
        if (this.state.selectedPaie == null || this.state.selectedPaie == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({showindicator: true});
            this.gestGemsysService.DeletePaie(this.state.selectedPaie).then(data => this.resultat(data.code, data.contenu));
        }
    }
    conf_delete_paie(data) {
        if (data !== null && data !== undefined) {
            this.setState({ selectedPaie: data });
            this.setState({ msg: data.codePaie });
            this.setState({ visible: true });
        }
    }
    conf_payer(data) {
        if (data !== null && data !== undefined) {
            data.estPaye='Oui';
            this.setState({showindicator: true});
            this.gestGemsysService.UpdatePaie(data).then(data => this.resultat(data.code, data.contenu));
        }
    }
    render() {
        let personnels = null;
        if (this.state.liste_personnel != null && this.state.liste_personnel != undefined) {
            personnels = this.state.liste_personnel.map(x => {
                return { value: x.matrPers, label: x.prenomPers + " " + x.nomPers }
            });
        }
        let rpersonnels = [
            { value: 'Tous', label: 'Personnel' }
        ];
        rpersonnels = rpersonnels.concat(personnels);
        let liste_mois = [
            { label: 'Janvier', value: '01' },
            { label: 'Février', value: '02' },
            { label: 'Mars', value: '03' },
            { label: 'Avril', value: '04' },
            { label: 'Mai', value: '05' },
            { label: 'Juin', value: '06' },
            { label: 'Juillet', value: '07' },
            { label: 'Août', value: '08' },
            { label: 'Septembre', value: '09' },
            { label: 'Octobre', value: '10' },
            { label: 'Novembre', value: '11' },
            { label: 'Décembre', value: '12' }
        ];
        let libelles = [
            { label: 'Primes de fonction', value: 'Primes de fonction' },
            { label: 'Salaire enseignant', value: 'Salaire enseignant' }
        ];
        let etats = [
            { label: 'Non Payé', value: 'Non' },
            { label: 'Payé', value: 'Oui' }
        ];
        
        const dialogFooter = (
            <div>
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_paie();
                }} label="Oui" loading={this.state.showindicator} className="p-button-danger" />
            </div>
        );
        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer la ligne sélectionnée ?
                    </div>
                </Dialog>
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title" >
                            <Panel header="Génération de la paie" >
                                <div className="grid">
                                    <div className="col-12 md:col-3">
                                        <label htmlFor="moisPaie">Mois *</label>
                                        <Dropdown options={liste_mois} value={this.state.moisPaie} onChange={(e) => this.setState({ moisPaie: e.target.value })} autoWidth={false} />
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <label htmlFor="libPaie">Libellé *</label>
                                        <Dropdown options={libelles} value={this.state.libPaie} onChange={(e) => this.setState({ libPaie: e.target.value })} autoWidth={false} />
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <label htmlFor="estPaye">Etat *</label>
                                        <Dropdown options={etats} value={this.state.estPaye} onChange={(e) => this.setState({ estPaye: e.target.value })} autoWidth={false} />
                                    </div>

                                    <div className="col-12 md:col-3">
                                        <label htmlFor="estPaye">.</label>
                                        <Button label="Générer paie" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_paie()} />
                                    </div>
                                </div>
                            </Panel>

                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999', marginRight: 5 }}> Options de recherche</span>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-2" >
                                        <Dropdown options={[{label: 'Mois', value: 'Tous' }].concat(liste_mois)} placeholder="Mois" value={this.state.rmois} onChange={(e) => this.setState({ rmois: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={rpersonnels} placeholder="Personnel" optionLabel="label" value={this.state.rmatrPers} onChange={(e) => this.setState({ rmatrPers: e.target.value })}
                                            itemTemplate={(option) => {
                                                if (!option.value) {
                                                    return option.label;
                                                }
                                                else {
                                                    return (
                                                        <div className="grid" >
                                                            <div className="col-12 md:col-3">
                                                                <label>{option.value}</label>
                                                            </div>
                                                            <div className="col-12 md:col-9">
                                                                <label>{option.label}</label>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            }} autoWidth={false} filter={true} filterBy='label,value' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                    </div>
                                    <div className="col-12 md:col-3" >
                                        <Dropdown options={[{ label: 'Libelle', value: 'Tous' }].concat(libelles)} placeholder="Libelle" value={this.state.rlibelle} onChange={(e) => this.setState({ rlibelle: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Dropdown options={[{ label: 'Etat', value: 'Tous' }].concat(etats)} placeholder="Etat" value={this.state.retat} onChange={(e) => this.setState({ retat: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-2" >
                                        <Button icon='pi pi-search' loading={this.state.showindicator} label='Rechercher' onClick={() => this.get_liste_paies()} />
                                    </div>
                                </div>

                            </div>
                            <DataTable value={this.state.liste_paie} selectionMode="single" alwaysShowPaginator={false} style={{ minHeight: '500px' }}
                                onRowClick={e => this.setState({ selectedPaie: e.data })} onRowSelect={e => this.setState({ selectedPaie: e.data })}
                                responsive={true} selection={this.state.selectedPaie} dataKey="idPaie" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}>
                                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="matrPers.matrPers" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="matrPers.prenomPers" body={(rowData, column) => {
                                    return <div>
                                        <span> {rowData.matrPers.prenomPers + " " + rowData.matrPers.nomPers} </span>
                                    </div>
                                }} header="Personnel" style={{ width: '26%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="libPaie" header="Libelle" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="moisPaie" header="Mois" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="salaireB" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'black' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.salaireB))}</span>
                                    </div>
                                }} header="Salaire_brut" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtPaie" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.mtPaie))}</span>
                                    </div>
                                }} header="Mt_Payé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="mtBon" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{this.gestGemsysService.formaterValueSep(parseFloat(rowData.mtBon))}</span>
                                    </div>
                                }} header="Mt_bon" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}