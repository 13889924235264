import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import  GestGemsysService  from '../service/GestGemsysService';
import { Panel } from 'primereact/panel';

import './styles.css';

export class FormSaisieNote extends Component {
    constructor() {
        super();
        this.state = {
            module: 'Module 1',
            codemat: 'Tous',
            codeClasse: null,
            matricule: 'Tous',
            liste_notes: [],
            liste_classes: [],
            liste_matieres: [],
            liste_eleves: [],
            selectedNotes: [],
            msg: '',
            modif: false,
            visible: false,
            maxNote: 20,
            showindicator: false,
            selectedEleve: null,
            selectedNote: null,

            ne1: 0,
            ne2: 0,
            ne3: 0,
            ne4: 0,
            ne5: 0,
            ne6: 0,
            ne7: 0,
            ne8: 0,
            ne9: 0,
            no1: 0,
            no2: 0,
            no3: 0,
            no4: 0,
            no5: 0,
            no6: 0,
            no7: 0,
            no8: 0,
            no9: 0,

            noteCours: 0,
            noteCompo: 0,
            note3: 0,

            ncrsM1: 0,
            ncrsM2: 0,
            ncmpM1: 0,
            ncmpM2: 0,

        };
        this.edit_note = this.edit_note.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);

        this.noteCoursEditor = this.noteCoursEditor.bind(this);
        this.noteCompoEditor = this.noteCompoEditor.bind(this);
        this.note3Editor = this.note3Editor.bind(this);

        this.ne1Editor = this.ne1Editor.bind(this);
        this.ne2Editor = this.ne2Editor.bind(this);
        this.ne3Editor = this.ne3Editor.bind(this);
        this.ne4Editor = this.ne4Editor.bind(this);
        this.ne5Editor = this.ne5Editor.bind(this);
        this.ne6Editor = this.ne6Editor.bind(this);
        this.ne7Editor = this.ne7Editor.bind(this);
        this.ne8Editor = this.ne8Editor.bind(this);
        this.ne9Editor = this.ne9Editor.bind(this);

        this.no1Editor = this.no1Editor.bind(this);
        this.no2Editor = this.no2Editor.bind(this);
        this.no3Editor = this.no3Editor.bind(this);
        this.no4Editor = this.no4Editor.bind(this);
        this.no5Editor = this.no5Editor.bind(this);
        this.no6Editor = this.no6Editor.bind(this);
        this.no7Editor = this.no7Editor.bind(this);
        this.no8Editor = this.no8Editor.bind(this);
        this.no9Editor = this.no9Editor.bind(this);

        this.ncrsM1Editor = this.ncrsM1Editor.bind(this);
        this.ncrsM2Editor = this.ncrsM2Editor.bind(this);

        this.ncmpM1Editor = this.ncmpM1Editor.bind(this);
        this.ncmpM2Editor = this.ncmpM2Editor.bind(this);

        this.gestGemsysService = new GestGemsysService();

    }
    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    onEditorValueChange(props, value) {
        let updateds = [...props.value];
        updateds[props.rowIndex][props.field] = value;
        this.setState({ liste_notes: updateds });
    }

    inputNumberEditor(props, field) {
        return <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={this.state.maxNote} value={props.rowData[field]} onChange={(e) => {
            if (e.value > this.state.maxNote) {
                this.onEditorValueChange(props, this.state.maxNote)
            } else {
                this.onEditorValueChange(props, e.value)
            }
        }} />;
    }

    noteCoursEditor(props) {
        return this.inputNumberEditor(props, 'noteCours');
    }
    noteCompoEditor(props) {
        return this.inputNumberEditor(props, 'noteCompo');
    }
    note3Editor(props) {
        return this.inputNumberEditor(props, 'note3');
    }

    ncrsM1Editor(props) {
        return this.inputNumberEditor(props, 'ncrsM1');
    }
    ncmpM1Editor(props) {
        return this.inputNumberEditor(props, 'ncmpM1');
    }
    ncrsM2Editor(props) {
        return this.inputNumberEditor(props, 'ncrsM2');
    }
    ncmpM2Editor(props) {
        return this.inputNumberEditor(props, 'ncmpM2');
    }

    no1Editor(props) {
        return this.inputNumberEditor(props, 'no1');
    }
    no2Editor(props) {
        return this.inputNumberEditor(props, 'no2');
    }
    no3Editor(props) {
        return this.inputNumberEditor(props, 'no3');
    }
    no4Editor(props) {
        return this.inputNumberEditor(props, 'no4');
    }
    no5Editor(props) {
        return this.inputNumberEditor(props, 'no5');
    }
    no6Editor(props) {
        return this.inputNumberEditor(props, 'no6');
    }
    no7Editor(props) {
        return this.inputNumberEditor(props, 'no7');
    }
    no8Editor(props) {
        return this.inputNumberEditor(props, 'no8');
    }
    no9Editor(props) {
        return this.inputNumberEditor(props, 'no9');
    }


    ne1Editor(props) {
        return this.inputNumberEditor(props, 'ne1');
    }
    ne2Editor(props) {
        return this.inputNumberEditor(props, 'ne2');
    }
    ne3Editor(props) {
        return this.inputNumberEditor(props, 'ne3');
    }
    ne4Editor(props) {
        return this.inputNumberEditor(props, 'ne4');
    }
    ne5Editor(props) {
        return this.inputNumberEditor(props, 'ne5');
    }
    ne6Editor(props) {
        return this.inputNumberEditor(props, 'ne6');
    }
    ne7Editor(props) {
        return this.inputNumberEditor(props, 'ne7');
    }
    ne8Editor(props) {
        return this.inputNumberEditor(props, 'ne8');
    }
    ne9Editor(props) {
        return this.inputNumberEditor(props, 'ne9');
    }

    componentDidMount() {
        this.gestGemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
                });
                this.setState({ liste_classes: liste });
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.typeOp !== this.props.match.params.typeOp) {
            this.setState({ liste_notes: [] });
        }
    }

    get_liste_matiere_by_classe(codeClasse) {
        this.gestGemsysService.getMatiere_by_classe(codeClasse).then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.codemat, label: x.libmat }
                });
                this.setState({ liste_matieres: liste });
            }
        });
    }

    get_liste_inscrit_by_classe(codeClasse) {
        this.setState({ showindicator: true });
        this.gestGemsysService.getliste_eleve_by_inscrit(codeClasse).then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.matricule.matricule, label: x.matricule.prenomEleve + " " + x.matricule.nomEleve, photo: x.matricule.photo }
                });
                this.setState({ liste_eleves: liste });
            }
            this.setState({ showindicator: false });
        });
    }

    get_liste_notes() {
        let rval = 0;
        if (this.get_type_operation() == 'validation') {
            rval = 1;
        }
        this.setState({ showindicator: true });
        this.gestGemsysService.getListe_notes(this.state.matricule, this.state.codeClasse, this.state.codemat, rval, this.state.module).then(data => {
            if (data != null && data != undefined) {
                for (var i = data.length; i--;) {
                    if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
                        let moy1 = this.gestGemsysService.formatDecimal(parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].ncrsM1) + 2 * parseFloat(data[i].ncmpM1)) / 3);
                        let moy2 = this.gestGemsysService.formatDecimal(parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].ncrsM2) + 2 * parseFloat(data[i].ncmpM2)) / 3);
                        data[i].moyM1 = moy1;
                        data[i].moyM2 = moy2;
                        data[i].moyG = this.gestGemsysService.formatDecimal((moy1 + moy2) / 2);
                    } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
                        let moy1 = this.gestGemsysService.formatDecimal(((parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no1) + 2 * parseFloat(data[i].ne1)) / 3) + (parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no2) + 2 * parseFloat(data[i].ne2)) / 3) + (parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no3) + 2 * parseFloat(data[i].ne3)) / 3)) / 3);
                        let moy2 = this.gestGemsysService.formatDecimal(((parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no4) + 2 * parseFloat(data[i].ne4)) / 3) + (parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no5) + 2 * parseFloat(data[i].ne5)) / 3) + (parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no6) + 2 * parseFloat(data[i].ne6)) / 3)) / 3);
                        let moy3 = this.gestGemsysService.formatDecimal(((parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no7) + 2 * parseFloat(data[i].ne7)) / 3) + (parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no8) + 2 * parseFloat(data[i].ne8)) / 3) + (parseFloat(data[i].codemat.coeff) * (parseFloat(data[i].no9) + 2 * parseFloat(data[i].ne9)) / 3)) / 3);
                        data[i].moyM1 = moy1;
                        data[i].moyM2 = moy2;
                        data[i].moyM3 = moy3;
                        data[i].moyG = this.gestGemsysService.formatDecimal((moy1 + moy2 + moy3) / 3);
                    } else {
                        let moy = this.gestGemsysService.formatDecimal(parseFloat(data[i].codemat.pd1) * parseFloat(data[i].codemat.noteCours) + parseFloat(data[i].codemat.pd2) * parseFloat(data[i].codemat.noteCompo) + parseFloat(data[i].codemat.pd3) * parseFloat(data[i].codemat.note3));
                        data[i].moyG = moy;
                    }
                }
            }
            this.setState({ liste_notes: data, showindicator: false });
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annuler_noter();
            this.get_liste_notes();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
    }

    edit_note(data) {
        if (data != null && data != undefined) {
            this.setState({ ne1: data.ne1 });
            this.setState({ ne2: data.ne2 });
            this.setState({ ne3: data.ne3 });
            this.setState({ ne4: data.ne4 });
            this.setState({ ne5: data.ne5 });
            this.setState({ ne6: data.ne6 });
            this.setState({ ne7: data.ne7 });
            this.setState({ ne8: data.ne8 });
            this.setState({ ne9: data.ne9 });
            this.setState({ no1: data.no1 });
            this.setState({ no2: data.no2 });
            this.setState({ no3: data.no3 });
            this.setState({ no4: data.no4 });
            this.setState({ no5: data.no5 });
            this.setState({ no6: data.no6 });
            this.setState({ no7: data.no7 });
            this.setState({ no8: data.no8 });
            this.setState({ no9: data.no9 });

            this.setState({ noteCours: data.noteCours });
            this.setState({ noteCompo: data.noteCompo });
            this.setState({ note3: data.note3 });

            this.setState({ ncrsM1: data.ncrsM1 });
            this.setState({ ncrsM2: data.ncrsM2 });
            this.setState({ ncmpM1: data.ncmpM1 });
            this.setState({ ncmpM2: data.ncmpM2 });

            this.setState({ selectedNote: data });
            this.setState({ modif: true, visible: true });

        }
    }

    get_type_operation() {
        let mode = 'saisie';
        if (this.props.match.params.typeOp != null && this.props.match.params.typeOp != undefined) {
            mode = this.props.match.params.typeOp;
        }
        return mode;
    }

    annuler_noter() {
        this.setState({ visible: false, modif: false });
    }

    enreg_noter() {
        if (this.state.modif == true) {
            if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
                if (this.state.ncrsM1 == null || this.state.ncrsM2 == null || this.state.ncmpM1 == null || this.state.ncmpM2 == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les notes Professionnel' });
                    return;
                }
            } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
                if (this.state.no1 == null || this.state.no2 == null || this.state.no3 == null || this.state.no4 == null || this.state.no5 == null || this.state.no6 == null || this.state.no7 == null || this.state.no8 == undefined || this.state.no9 == null || this.state.ne1 == null || this.state.ne2 == null || this.state.ne3 == null || this.state.ne4 == null || this.state.ne5 == null || this.state.ne6 == null || this.state.ne7 == null || this.state.ne8 == null || this.state.ne9 == null) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les notes' });
                    return;
                }
            } else {
                if (this.state.noteCours == null || this.state.noteCompo == null || this.state.note3 == null ) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir les notes' });
                    return;
                }
            }
            this.state.selectedNote.note3 = this.state.note3;
            this.state.selectedNote.noteCompo = this.state.noteCompo;
            this.state.selectedNote.noteCours = this.state.noteCours;
            this.state.selectedNote.ncmpM1 = this.state.ncmpM1;
            this.state.selectedNote.ncmpM2 = this.state.ncmpM2;
            this.state.selectedNote.ncrsM1 = this.state.ncrsM1;
            this.state.selectedNote.ncrsM2 = this.state.ncrsM2;
            this.state.selectedNote.no1 = this.state.no1;
            this.state.selectedNote.no2 = this.state.no2;
            this.state.selectedNote.no3 = this.state.no3;
            this.state.selectedNote.no4 = this.state.no4;
            this.state.selectedNote.no5 = this.state.no5;
            this.state.selectedNote.no6 = this.state.no6;
            this.state.selectedNote.no7 = this.state.no7;
            this.state.selectedNote.no8 = this.state.no8;
            this.state.selectedNote.no9 = this.state.no9;
            this.state.selectedNote.ne1 = this.state.ne1;
            this.state.selectedNote.ne2 = this.state.ne2;
            this.state.selectedNote.ne3 = this.state.ne3;
            this.state.selectedNote.ne4 = this.state.ne4;
            this.state.selectedNote.ne5 = this.state.ne5;
            this.state.selectedNote.ne6 = this.state.ne6;
            this.state.selectedNote.ne7 = this.state.ne7;
            this.state.selectedNote.ne8 = this.state.ne8;
            this.state.selectedNote.ne9 = this.state.ne9;
            this.gestGemsysService.UpdateNoter(this.state.selectedNote).then(data => {
                if (data != null && data != undefined && data != '') {
                    this.resultat(data.code, data.contenu);
                }
            });
        } else {
            if (this.state.selectedNotes == null || this.state.selectedNotes == undefined || this.state.selectedNotes.length <= 0) {
                this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes' });
            } else {
                let liste = this.state.selectedNotes;
                let modif = this.get_type_operation() == 'saisie' ? 1 : 2;
                for (var i = liste.length; i--;) {
                    liste[i].iduser = this.gestGemsysService.get_userconnected().numUtil;
                    if (this.state.module == 'Module 1') {
                        liste[i].modif1 = modif;
                    } else if (this.state.module == 'Module 2') {
                        liste[i].modif2 = modif;
                    } else {
                        liste[i].modif3 = modif;
                    }
                }
                this.gestGemsysService.UpdateNoterBloc(this.state.selectedNotes).then(data => {
                    if (data != null && data != undefined && data != '') {
                        this.resultat(data.code, data.contenu);
                    }
                });
            }
        }

    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gestGemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    actionTemplate(rowData) {
        return <Button disabled={this.gestGemsysService.get_userconnected().profilu!=='Administrateur'  && this.gestGemsysService.get_userconnected().profilu!=='Recteur' && this.gestGemsysService.get_userconnected().profilu!=='Directeur général'} onClick={() => this.edit_note(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" />
    }

    get_tableNote() {
        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            if (this.state.module == 'Module 1') {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes} className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.prenomEleve + " " + rowData.idInsc.matricule.nomEleve} </span>
                        </div>
                    }} header="Prénoms_et_Nom" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="codemat.libmat" header="Matière" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="ncrsM1" header="N cours" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ncrsM1Editor} />
                    <Column field="ncmpM1" header="N compo" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ncmpM1Editor} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.ncrsM1) + 2 * parseFloat(rowData.ncmpM1)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            } else {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '5%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.prenomEleve + " " + rowData.idInsc.matricule.nomEleve} </span>
                        </div>
                    }} header="Prénoms_et_Nom" style={{ width: '25%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="codemat.libmat" header="Matière" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="ncrsM2" header="N cours" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ncrsM2Editor} />
                    <Column field="ncmpM2" header="N compo" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ncmpM2Editor} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.ncrsM2) + 2 * parseFloat(rowData.ncmpM2)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            }
        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            if (this.state.module == 'Module 1') {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '4%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.prenomEleve + " " + rowData.idInsc.matricule.nomEleve} </span>
                        </div>
                    }} header="Prénoms_et_Nom" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="codemat.libmat" header="Matière" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="no1" header="NO Oct" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no1Editor} />
                    <Column field="ne1" header="NE Oct" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne1Editor} />
                    <Column field="no2" header="NO Nov" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no1Editor} />
                    <Column field="ne2" header="NE Nov" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne1Editor} />
                    <Column field="no3" header="NO Dec" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no1Editor} />
                    <Column field="ne3" header="NE Dec" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne1Editor} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(((parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no1) + 2 * parseFloat(rowData.ne1)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no2) + 2 * parseFloat(rowData.ne2)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no3) + 2 * parseFloat(rowData.ne3)) / 3)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            } else if (this.state.module == 'Module 2') {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '4%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.prenomEleve + " " + rowData.idInsc.matricule.nomEleve} </span>
                        </div>
                    }} header="Prénoms_et_Nom" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="codemat.libmat" header="Matière" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="no1" header="NO Jan" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no4Editor} />
                    <Column field="ne1" header="NE Jan" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne4Editor} />
                    <Column field="no2" header="NO Fev" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no5Editor} />
                    <Column field="ne2" header="NE Fev" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne5Editor} />
                    <Column field="no3" header="NO Mar" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no6Editor} />
                    <Column field="ne3" header="NE Mar" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne6Editor} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(((parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no4) + 2 * parseFloat(rowData.ne4)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no5) + 2 * parseFloat(rowData.ne5)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no6) + 2 * parseFloat(rowData.ne6)) / 3)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            } else {
                return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                    onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                    selection={this.state.selectedNotes}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                    <Column selectionMode="multiple" style={{ width: '4%' }} />
                    <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                    <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                        return <div>
                            <span> {rowData.idInsc.matricule.prenomEleve + " " + rowData.idInsc.matricule.nomEleve} </span>
                        </div>
                    }} header="Prénoms_et_Nom" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="codemat.libmat" header="Matière" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="no1" header="NO Avr" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no7Editor} />
                    <Column field="ne1" header="NE Avr" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne7Editor} />
                    <Column field="no2" header="NO Mai" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no8Editor} />
                    <Column field="ne2" header="NE Mai" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne8Editor} />
                    <Column field="no3" header="NO Jun" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.no9Editor} />
                    <Column field="ne3" header="NE Jun" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} editor={this.ne9Editor} />
                    <Column body={(rowData, column) => {
                        let moy = this.gestGemsysService.formatDecimal(((parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no7) + 2 * parseFloat(rowData.ne7)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no8) + 2 * parseFloat(rowData.ne8)) / 3) + (parseFloat(rowData.codemat.coeff) * (parseFloat(rowData.no9) + 2 * parseFloat(rowData.ne9)) / 3)) / 3);
                        return <div>
                            <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                        </div>
                    }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            }
        } else {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }}
                onSelectionChange={e => this.setState({ selectedNotes: e.value })}
                selection={this.state.selectedNotes}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={300}>
                <Column selectionMode="multiple" style={{ width: '3%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '5%' }} />
                <Column field="idInsc.matricule.matricule" header="Matricule" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="idInsc.matricule.prenomEleve" body={(rowData) => {
                    return <div>
                        <span> {rowData.idInsc.matricule.prenomEleve + " " + rowData.idInsc.matricule.nomEleve} </span>
                    </div>
                }} header="Prénoms_et_Nom" style={{ width: '24%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="codemat.libmat" header="Matière" style={{ width: '30%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="noteCours" header="Note1" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="noteCompo" header="Note2" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="note3" header="Note3" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column body={(rowData, column) => {
                    let moy = this.gestGemsysService.formatDecimal(parseFloat(rowData.codemat.pd1) * parseFloat(rowData.noteCours) + parseFloat(rowData.codemat.pd2) * parseFloat(rowData.noteCompo) + parseFloat(rowData.codemat.pd3) * parseFloat(rowData.note3));
                    return <div>
                        <span style={{ color: moy < 10 ? 'red' : '#000' }}>{moy}</span>
                    </div>
                }} header="Moy" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
            </DataTable>
        }

    }

    get_tableNote_modif() {
        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }} selectionMode="single"
                onSelectionChange={e => this.setState({ selectedNote: e.value })}
                selection={this.state.selectedNote}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={30}>
                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '7%' }} />
                <Column field="codemat.libmat" header="Matière" style={{ width: '51%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyM1" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM1 < 10 ? 'red' : '#000' }}>{rowData.moyM1}</span>
                    </div>
                }} header="M1" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyM2" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM2 < 10 ? 'red' : '#000' }}>{rowData.moyM2}</span>
                    </div>
                }} header="M2" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyG" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyG < 10 ? 'red' : '#000' }}>{rowData.moyG}</span>
                    </div>
                }} header="Moy G" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
            </DataTable>

        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }} selectionMode="single"
                onSelectionChange={e => this.setState({ selectedNote: e.value })}
                selection={this.state.selectedNote}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={30}>
                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '7%' }} />
                <Column field="codemat.libmat" header="Matière" style={{ width: '40%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyM1" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM1 < 10 ? 'red' : '#000' }}>{rowData.moyM1}</span>
                    </div>
                }} header="M1" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyM2" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM2 < 10 ? 'red' : '#000' }}>{rowData.moyM2}</span>
                    </div>
                }} header="M2" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyM3" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyM3 < 10 ? 'red' : '#000' }}>{rowData.moyM2}</span>
                    </div>
                }} header="M3" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyG" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyG < 10 ? 'red' : '#000' }}>{rowData.moyG}</span>
                    </div>
                }} header="Moy G" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
            </DataTable>
        } else {
            return <DataTable value={this.state.liste_notes} style={{ minHeight: '700px' }} selectionMode="single"
                onSelectionChange={e => this.setState({ selectedNote: e.value })}
                selection={this.state.selectedNote}  className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={30}>
                <Column header="Actions" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                <Column body={this.onIndexTemplate} header="No" style={{ width: '7%' }} />
                <Column field="codemat.libmat" header="Matière" style={{ width: '73%' }} filter={true} filterMatchMode='contains' sortable={true} />
                <Column field="moyG" body={(rowData, column) => {
                    return <div>
                        <span style={{ color: rowData.moyG < 10 ? 'red' : '#000' }}>{rowData.moyG}</span>
                    </div>
                }} header="Moy" style={{ width: '13%' }} filter={true} filterMatchMode='contains' sortable={true} />
            </DataTable>
        }
    }

    render() {
        let liste_module = [];
        if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            liste_module = [
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
            ];
        } else if (this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            liste_module = [
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
                { value: 'Module 3', label: 'Module 3' }
            ];
        } else {
            liste_module = [
                { value: 'Module 1', label: '1ère évaluation' },
                { value: 'Module 2', label: 'Session' },
                { value: 'Module 3', label: 'Reprise' }
            ];
        }
        const header = <div className="grid">
            <div className="col-11">
                <span className="p-float-label">
                    <Dropdown inputId="dropdown" options={this.state.liste_classes} placeholder="Classe" value={this.state.codeClasse} onChange={(e) => this.setState({ codeClasse: e.target.value }, () => {
                        this.get_liste_inscrit_by_classe(this.state.codeClasse);
                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                    <label htmlFor="dropdown">Classe</label>
                </span>
            </div>
            <div className="col-1">
                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', float: 'right' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
            </div>
        </div>

        const dialogFooter = (
            <div>
                <Button icon="pi pi-ban" onClick={() => this.annuler_noter()} label="Annuler" className="p-button-secondary" />
                <Button label="Enregistrer" icon="pi pi-check" onClick={(e) => this.enreg_noter()} />
            </div>
        );

        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Modification" visible={this.state.visible} style={{ width: '70%' }} modal={true} onHide={(e) => this.setState({ visible: false })} footer={dialogFooter}>
                    <div className="col-12">
                        {this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Université' ?
                            <div className="grid">

                                <div className="col-12 md:col-4">
                                    <label >Note 1 *</label>
                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.noteCours} onChange={(e) => { this.setState({ noteCours: e.value }) }} />
                                </div>

                                <div className="col-12 md:col-4">
                                    <label >Note 2 *</label>
                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.noteCompo} onChange={(e) => { this.setState({ noteCompo: e.value }) }} />
                                </div>

                                <div className="col-12 md:col-4">
                                    <label >Note 3 *</label>
                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.note3} onChange={(e) => { this.setState({ note3: e.value }) }} />
                                </div>
                            </div> : null}

                        {this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel' ?
                            <div className="grid">
                                <div className="col-12 md:col-6">
                                    <Panel header="Module 1">
                                        <div className="grid">
                                            <div className="col-6 md:col-6">
                                                <label >Note cours *</label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncrsM1} onChange={(e) => { this.setState({ ncrsM1: e.value }) }} />
                                            </div>

                                            <div className="col-6 md:col-6">
                                                <label >Note compo *</label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncmpM1} onChange={(e) => { this.setState({ ncmpM1: e.value }) }} />
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                                <div className="col-12 md:col-6">
                                    <Panel header="Module 2">
                                        <div className="grid">
                                            <div className="col-6 md:col-6">
                                                <label >Note cours *</label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncrsM2} onChange={(e) => { this.setState({ ncrsM2: e.value }) }} />
                                            </div>
                                            <div className="col-6 md:col-6">
                                                <label >Note compo *</label>
                                                <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={20} value={this.state.ncmpM2} onChange={(e) => { this.setState({ ncmpM2: e.value }) }} />
                                            </div>
                                        </div>
                                    </Panel>
                                </div>
                            </div>

                            : null}


                        {this.gestGemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire' ?
                            <>
                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <Panel header="Octobre">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no1} onChange={(e) => { this.setState({ no1: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne1} onChange={(e) => { this.setState({ ne1: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Novembre">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no2} onChange={(e) => { this.setState({ no2: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne2} onChange={(e) => { this.setState({ ne2: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Décembre">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no3} onChange={(e) => { this.setState({ no3: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne3} onChange={(e) => { this.setState({ ne3: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>

                                </div>

                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <Panel header="Janvier">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no4} onChange={(e) => { this.setState({ no4: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne4} onChange={(e) => { this.setState({ ne4: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Février">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no5} onChange={(e) => { this.setState({ no5: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne5} onChange={(e) => { this.setState({ ne5: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Mars">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no6} onChange={(e) => { this.setState({ no6: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne6} onChange={(e) => { this.setState({ ne6: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>

                                </div>
                                <div className="grid">
                                    <div className="col-12 md:col-4">
                                        <Panel header="Avril">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no7} onChange={(e) => { this.setState({ no7: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne7} onChange={(e) => { this.setState({ ne7: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Mai">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no8} onChange={(e) => { this.setState({ no8: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne8} onChange={(e) => { this.setState({ ne8: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Panel header="Juin">
                                            <div className="grid">
                                                <div className="col-12 md:col-6">
                                                    <label >N orale *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.no9} onChange={(e) => { this.setState({ no9: e.value }) }} />
                                                </div>
                                                <div className="col-12 md:col-6">
                                                    <label >N écrite *</label>
                                                    <InputNumber mode="decimal" minFractionDigits={2} maxFractionDigits={2} max={10} value={this.state.ne9} onChange={(e) => { this.setState({ ne9: e.value }) }} />
                                                </div>
                                            </div>
                                        </Panel>
                                    </div>

                                </div>
                            </>
                            : null}

                    </div>
                </Dialog >
                {
                    this.get_type_operation() == 'modification' ?
                        <div className="card card-w-title">
                            <div className="grid">
                                <div className="col-12 md:col-5">
                                    <DataTable value={this.state.liste_eleves} style={{ minHeight: '570px' }}
                                        selectionMode="single" dataKey="value" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}
                                        onRowClick={e => this.setState({ selectedEleve: e.data }, () => {
                                            this.setState({ codemat: 'Tous', module: 'Tous', matricule: this.state.selectedEleve.value }, () => {
                                                this.get_liste_notes();
                                            })
                                        })} header={header} selection={this.state.selectedEleve} >
                                        <Column body={this.onIndexTemplate} header="No" style={{ width: '9%' }} />
                                        <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '13%' }} />
                                        <Column field="value" header="Matricule" style={{ width: '25%' }} filter={true} filterMatchMode='contains' />
                                        <Column field="label" body={(data) => {
                                            return <div>
                                                {data.label}
                                            </div>
                                        }} header="Prénoms_et_Nom" style={{ width: '54%' }} filter={true} filterMatchMode='contains' />
                                    </DataTable>
                                </div>
                                <div className="col-12 md:col-7">
                                    {this.get_tableNote_modif()}
                                </div>
                            </div>

                        </div>
                        :
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_classes} placeholder="Classe" value={this.state.codeClasse} onChange={(e) => this.setState({ codeClasse: e.target.value }, () => {
                                        this.get_liste_matiere_by_classe(this.state.codeClasse);
                                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '400px' }} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={[{ value: 'Tous', label: 'Matière' }].concat(this.state.liste_matieres)} placeholder="Matière" value={this.state.codemat} onChange={(e) => this.setState({ codemat: e.value }, () => {
                                        this.get_liste_inscrit_by_classe(this.state.codeClasse);
                                    })} filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{ width: '300px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={[{ value: 'Tous', label: 'Elève/Etudiant' }].concat(this.state.liste_eleves)} placeholder="Elève/Etudiant" value={this.state.matricule} onChange={(e) => this.setState({ matricule: e.value }, () => {
                                        //this.get_liste_notes();
                                    })}
                                        itemTemplate={(option) => {
                                            if (!option.value) {
                                                return option.label;
                                            }
                                            else {
                                                return (
                                                    <div className="grid" >
                                                        <div className="col-12 md:col-3">
                                                            <label>{option.value}</label>
                                                        </div>
                                                        <div className="col-12 md:col-9">
                                                            <label>{option.label}</label>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }} autoWidth={false} optionLabel='label' filter={true} filterBy='label,value' filterMatchMode='contains' panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown options={liste_module} placeholder="Module" value={this.state.module} onChange={(e) => this.setState({ module: e.value }, () => {
                                        this.forceUpdate();
                                    })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> :
                                        <Button label="Rechercher" style={{ width: 150 }} onClick={() => this.get_liste_notes()} />}
                                </div>
                            </div>
                            {this.get_tableNote()}
                            <br />
                            <center>
                                <Button label="Valider" style={{ width: 150 }} icon="pi pi-check" onClick={() => this.enreg_noter()} />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}

                            </center>
                        </div>
                }


            </div >

        );
    }
}
