import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';

export class EditionResultats extends Component {
    constructor() {
        super();
        this.state = {
            rcode_classe: 'Tous',
            module: 'Module 1',
            liste_data: [],
            liste_classe: [{ label: 'Classe', value: 'Tous' }],
            showindicator: false,
            moy_classe: '',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                }
            ],
        };
        this.conf_print = this.conf_print.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_classe() {
        this.setState({ showindicator: true });
        this.gemsysService.getClasse().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
                    });
                    this.setState({ liste_classe: this.state.liste_classe.concat(liste) });
                }
            }
        });
    }

    get_liste_data() {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            this.setState({
                showindicator: true,
                moy_classe: 0,
            });
            this.gemsysService.get_resultat_classe(this.state.rcode_classe, this.state.module).then(data => {
                this.setState({ liste_data: data, showindicator: false });
            });
        }
    }

    conf_print() {
        if (this.state.rcode_classe == "" || this.state.rcode_classe == "Tous") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une classe' });
        } else {
            this.gemsysService.generer_resultat_classe('', this.state.rcode_classe, this.state.module);
        }
    }

    componentDidMount() {
        this.get_liste_classe();
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    render() {
        let liste_module = [];
        if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Professionnel') {
            liste_module = [
                { value: 'Tous', label: 'Module' },
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
            ];
        } else if (this.gemsysService.get_userconnected().idEtabl.typeEtabl == 'Pré-universitaire') {
            liste_module = [
                { value: 'Tous', label: 'Module' },
                { value: 'Module 1', label: 'Module 1' },
                { value: 'Module 2', label: 'Module 2' },
                { value: 'Module 3', label: 'Module 3' }
            ];
        } else {
            liste_module = [
                { value: 'Tous', label: 'Module' },
                { value: 'Module 1', label: '1ère évaluation' },
                { value: 'Module 2', label: 'Session' },
                { value: 'Module 3', label: 'Reprise' }
            ];
        }
        return (
            <div className="p-fluid">
                 <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-4">
                                    <Dropdown options={this.state.liste_classe} placeholder="Sélectionner" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_module} placeholder="Module" value={this.state.module} onChange={(e) => this.setState({ module: e.value }, () => {
                                        this.get_liste_data();
                                    })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px' }}  ref={(el) => { this.dt = el; }}
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}>
                                <Column field="ordre" header="Rang" style={{ width: '5%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matrEtud" header="Matricule" style={{ width: '15%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="nomEtud" header="Nom" style={{ width: '13%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="prenomEtu" header="Prénoms" style={{ width: '30%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="sexe" header="Sexe" style={{ width: '8%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="telEtud" header="Téléphone" style={{ width: '12%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="moyc" header="Moy" style={{ width: '8%' }} sortable={true} filter={true} filterMatchMode='contains' />
                                <Column field="mention" header="Appréciation" style={{ width: '14%' }} sortable={true} filter={true} filterMatchMode='contains' />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}