import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import GestGemsysService from '../service/GestGemsysService';
import './styles.css';

export class FormReinscriptionBloc extends Component {
    constructor() {
        super();
        this.state = {
            rsession: '',
            rclasse_src: '',
            rclasse_dest: '',
            type: 'Réinscription',
            optNotif: 'Non',
            liste_msgs: [],
            selectedInsc: [],
            liste_session: [],
            liste_classe_src: [],
            liste_classe_dest: [],
            liste_inscrit_old: [],
            msg: '',
            modif: false,
            visible: false,
            showindicator: false
        };

        this.gestGemsysService = new GestGemsysService();

    }
    componentDidMount() {
        this.get_liste_classeDest();
        this.get_liste_dossier();
    }

   
    get_liste_dossier() {
        this.gestGemsysService.getDossier().then(data => this.setState({ liste_session: data }));
    }

    get_liste_classeSource(annee) {
        this.gestGemsysService.getClasseByAnnee(annee).then(data => this.setState({ liste_classe_src: data }));
    }


    get_liste_classeDest() {

        this.gestGemsysService.getClasse().then(data => this.setState({ liste_classe_dest: data }));
    }

    get_liste_inscritOld(codeclasse) {
        this.setState({showindicator: true});
        this.gestGemsysService.getliste_eleve_by_inscrit(codeclasse).then(data => this.setState({ liste_inscrit_old: data,showindicator: false }));
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }

    resultatInscrit(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reinscrit();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({showindicator: false});
    }


    annule_reinscrit() {
        this.setState({ type: '' });
        this.setState({ optNotif: '' });
        this.setState({ rclasse_dest: '' });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_reiensc_enbloc() {
        if (this.state.selectedInsc == null || this.state.selectedInsc == undefined || this.state.selectedInsc.length<=0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les lignes' });
        } else if (this.state.rclasse_dest == null || this.state.rclasse_dest == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la classe de destination' });
        }else if (this.state.rclasse_dest == this.state.rclasse_src) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner une autre classe de destination différente de la source' });
        } else {
            this.setState({showindicator: true});
            let data_value={liste_inscrit: this.state.selectedInsc,codeClasseDest: this.state.rclasse_dest,typeInsc: this.state.type,anneeInsc: this.gestGemsysService.get_rannee(),iduser: this.gestGemsysService.get_userconnected().numUtil,optNotif: this.state.optNotif};
            this.gestGemsysService.ReinscriptionBloc(data_value).then(data => {
                if (data != null && data != undefined && data.code == '200') {
                    this.resultatInscrit(data.code, "Réinscription réussie");
                } else this.resultatInscrit(data.code, data.contenu);
            });
        }
    }

    photoTemplate(rowData, column) {
        let fic = rowData.matricule.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gestGemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }


    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    render() {

        let session
        if (this.state.liste_session != null && this.state.liste_session != undefined) {
            session = this.state.liste_session.map(x => {
                return { value: x.annee, label: x.annee }
            });
        }

        let classe_src
        if (this.state.liste_classe_src != null && this.state.liste_classe_src != undefined) {
            classe_src = this.state.liste_classe_src.map(x => {
                return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
            });
        }

        let classe_dest
        if (this.state.liste_classe_dest != null && this.state.liste_classe_dest != undefined) {
            classe_dest = this.state.liste_classe_dest.map(x => {
                return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse }
            });
        }

        let types = [
            { label: 'Réinscription', value: 'Réinscription' },
            { label: 'Inscription', value: 'Inscription' }
        ];

        let optNotifs = [
            { label: 'Oui', value: 'Oui' },
            { label: 'Non', value: 'Non' }
        ];


        return (
            <div className="p-fluid">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="fa fa-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <hr />
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <label htmlFor="rsession">Session</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown options={session} value={this.state.rsession} onChange={(e) => {
                                        this.setState({ rsession: e.target.value }, () => {
                                            this.get_liste_classeSource(this.state.rsession);
                                        });
                                    }} autoWidth={false} filter filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="rclasse">Classe source</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown options={classe_src} value={this.state.rclasse_src} onChange={(e) => {
                                        this.setState({ rclasse_src: e.target.value }, () => {
                                            this.get_liste_inscritOld(this.state.rclasse_src);
                                        });
                                    }} autoWidth={false} filter filterBy='label' filterMatchMode='contains' />
                                </div>
                            </div>  
                            <DataTable value={this.state.liste_inscrit_old} style={{ minHeight: '400px' }}
                                selectionMode="multiple" 
                                onRowClick={e => this.setState({ selectedInsc: e.data })} 
                                onSelectionChange={e => this.setState({selectedInsc: e.value })}
                                selection={this.state.selectedInsc} dataKey="idInsc" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={10}>
                                <Column selectionMode="multiple" style={{ width: '3%' }} />
                                <Column field="Index" header="N°" body={this.onIndexTemplate} style={{ width: '4%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="matricule.matricule" header="Matricule" style={{ width: '12%' }} />
                                <Column field="matricule.prenomEleve" body={(data) => {
                                    return <div>
                                        {data.matricule.nomEleve + " " + data.matricule.prenomEleve}
                                    </div>
                                }} header="Nom et Prénoms" style={{ width: '35' }} />
                                <Column field="matricule.telEleve" header="Téléphone" style={{ width: '10%' }} />
                                <Column field="numStatut.statutAccademique" header="Statut accad." style={{ width: '15%' }} />
                                <Column field="numStatut.statutFinancier" header="Statut financier" style={{ width: '15%' }} />
                            </DataTable>
                            <div className="col-12">
                                <div className="grid">
                                    <div className="col-12 md:col-4" >
                                        <label htmlFor="classe">Classe destination *</label>
                                        <Dropdown options={classe_dest} value={this.state.rclasse_dest} onChange={(e) => this.setState({ rclasse_dest: e.target.value })} autoWidth={false} filter filterBy='label' filterMatchMode='contains' />
                                    </div>
                                    <div className="col-12 md:col-4" >
                                        <label htmlFor="type">Type *</label>
                                        <Dropdown options={types} value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })} autoWidth={false} />
                                    </div>
                                    <div className="col-12 md:col-4" >
                                        <label htmlFor="optNotif">Notification *</label>
                                        <Dropdown options={optNotifs} value={this.state.optNotif} onChange={(e) => this.setState({ optNotif: e.target.value })} autoWidth={false} />
                                    </div>
                                </div>
                                <center>
                                    <div className="grid" style={{ width: 150, marginTop: '0px' }}>
                                        <div className="col"><Button label="Valider" loading={this.state.showindicator} icon="pi pi-check" onClick={() => this.enreg_reiensc_enbloc()} /></div>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
