import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './styles.css';
import { SplitButton } from 'primereact/splitbutton';
import  GestGemsysService from '../service/GestGemsysService';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

export class EditionSituationFrais extends Component {
    constructor() {
        super();
        this.state = {
            rcode_enseign: 'Tous',
            liste_data: [],
            selectedListes: [],
            ttrest: 0,
            firest: 0,
            fipaye: 0,
            fbrest: 0,
            fbpaye: 0,
            fsrest: 0,
            fspaye: 0,

            liste_classe: [],
            liste_statut_accademique: [{ label: 'Statut accadémique', value: 'Tous' }],
            liste_statut_financier: [{ label: 'Statut financier', value: 'Tous' }],
            rlibelle: 'Tous',
            rcode_classe: 'Tous',
            titreDoc: 'Situation des versements',
            rconditionFiltre: 'Tous',
            rstatut_accademique: 'Tous',
            rstatut_financier: 'Tous',
            rval: 0,
            showindicator: false,
            liste_enseignement: [{ label: 'Enseignement', value: 'Tous' }],
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ]
        }
        this.conf_print = this.conf_print.bind(this);
        this.onIndexTemplate = this.onIndexTemplate.bind(this);
        this.gemsysService = new GestGemsysService();
    }

    get_liste_classe(codeEnseign) {
        this.gemsysService.getClasse().then(data => {
            if (data !== null && data !== undefined) {
                let liste = data.map(x => {
                    return { value: x.codeClasse, label: x.libClasse + " " + x.optClasse, codeEnseign: x.codeEnseign.codeEnseign }
                }).filter(function (el) {
                    return el.codeEnseign == codeEnseign;
                });
                this.setState({ liste_classe: this.state.liste_classe.concat(liste) });
            }
        });
    }

    remove_duplicates(arr) {
        let s = new Set(arr);
        let it = s.values();
        return Array.from(it);
    }

    get_liste_statut() {
        this.gemsysService.getStatut().then(data => {
            if (data !== null && data !== undefined) {
                let liste_f = this.remove_duplicates(data.map(x => { return x.statutFinancier })).map(x => { return { value: x, label: x } });
                let liste_a = this.remove_duplicates(data.map(x => { return x.statutAccademique })).map(x => { return { value: x, label: x } });
                this.setState({
                    liste_statut_accademique: this.state.liste_statut_accademique.concat(liste_a),
                    liste_statut_financier: this.state.liste_statut_financier.concat(liste_f)
                });
            }
        });
    }


    get_liste_data() {
        this.setState({
            showindicator: true,
            ttrest: 0,
            firest: 0,
            fipaye: 0,
            fbrest: 0,
            fbpaye: 0,
            fsrest: 0,
            fspaye: 0,
        });
        this.gemsysService.get_situation_frais(this.state.rcode_enseign, this.state.rcode_classe, this.state.rlibelle, this.state.rstatut_accademique, this.state.rstatut_financier, this.state.rval, this.state.rconditionFiltre, this.state.titreDoc).then(data => {
            this.setState({ liste_data: data, showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let ttrest = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.ttrest
                    }, initialValue);
                    let firest = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.firest
                    }, initialValue);
                    let fipaye = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.fipaye
                    }, initialValue);
                    let fbrest = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.fbrest
                    }, initialValue);
                    let fbpaye = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.fbpaye
                    }, initialValue);
                    let fsrest = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.fsrest
                    }, initialValue);
                    let fspaye = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.fspaye
                    }, initialValue);
                    this.setState({
                        ttrest: this.gemsysService.formaterValueSep(ttrest),
                        firest: this.gemsysService.formaterValueSep(firest),
                        fipaye: this.gemsysService.formaterValueSep(fipaye),
                        fbrest: this.gemsysService.formaterValueSep(fbrest),
                        fbpaye: this.gemsysService.formaterValueSep(fbpaye),
                        fsrest: this.gemsysService.formaterValueSep(fsrest),
                        fspaye: this.gemsysService.formaterValueSep(fspaye)
                    });
                }
            }
        });
    }

    conf_print(format) {
        this.gemsysService.generer_situation_frais(this.state.rcode_enseign, this.state.rcode_classe, this.state.rlibelle, this.state.rstatut_accademique, this.state.rstatut_financier, this.state.rval, this.state.rconditionFiltre, this.state.titreDoc, format);
    }

    componentDidMount() {
        this.get_liste_enseignement();
        this.get_liste_statut();
        this.get_liste_data();
    }

    get_liste_enseignement() {
        this.setState({ showindicator: true });
        this.gemsysService.getEnseignement().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.codeEnseign, label: x.libEnseign }
                    });
                    this.setState({ liste_enseignement: this.state.liste_enseignement.concat(liste) });
                }
            }
        });
    }

    onIndexTemplate(data, props) {
        return props.rowIndex + 1;
    }

    get_liste_eleve_by_classe(codeClasse) {
        this.setState({ showindicator: true });
        this.gemsysService.getliste_eleve_by_inscrit(codeClasse).then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.matricule, label: x.prenomEleve + " " + x.nomEleve }
                    });
                    this.setState({ liste_eleve: this.state.liste_eleve.concat(liste) });
                }
            }
        });
    }

    photoTemplate(rowData, column) {
        let fic = rowData.photo;
        if (fic === undefined || fic === null || fic === '') {
            fic = 'nophoto.png';
        }
        return <img src={this.gemsysService.download_file(fic, 'photos/')} alt='Photo' style={{ width: 45, height: 45 }} />
    }

    render() {

        let liste_libelle = [
            { label: 'Libelle', value: 'Tous' },
            { label: 'Frais scolarité', value: 'Frais scolarité' },
            { label: 'Frais inscription', value: 'Frais inscription' },
            { label: 'Frais réinscription', value: 'Frais réinscription' },
            { label: 'Frais badge', value: 'Frais badge' },
            { label: 'Frais APEAE', value: 'Frais APEAE' },
            { label: 'Frais Cyber', value: 'Frais Cyber' },
            { label: 'Ancien bon', value: 'Ancien bon' },
            { label: 'Autre recette', value: 'Autre recette' }
        ];
        let liste_condition = [
            { label: 'Condition', value: 'Tous' },
            { label: 'Montant restant superieur à', value: 'SUP' },
            { label: 'Montant restant Inferieur à', value: 'INF' },
            { label: 'Montant restant égal', value: 'EGAL' },
        ]
        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAL" colSpan={3} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.ttrest} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fipaye} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.firest} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fspaye} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fsrest} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fbpaye} style={{ color: '#000000', fontWeight: 'bold' }} />
                <Column footer={this.state.fbrest} style={{ color: '#000000', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;
 
        return (
            <div className="p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">
                            <i className="pi pi-search"></i>
                            <span style={{ fontSize: 14, marginLeft: 10, color: '#999999' }}>Options de recherche</span>
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_enseignement} placeholder="Sélectionner" value={this.state.rcode_enseign} onChange={(e) => this.setState({ rcode_enseign: e.value }, () => {
                                        this.get_liste_classe(this.state.rcode_enseign);
                                    })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'/>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_classe} placeholder="Classe" value={this.state.rcode_classe} onChange={(e) => this.setState({ rcode_classe: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' panelStyle={{width: '400px'}}/>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_libelle} value={this.state.rlibelle} onChange={(e) => this.setState({ rlibelle: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains'/>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_statut_accademique} value={this.state.rstatut_accademique} onChange={(e) => this.setState({ rstatut_accademique: e.value }, () => { })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={this.state.liste_statut_financier} value={this.state.rstatut_financier} onChange={(e) => this.setState({ rstatut_financier: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown options={liste_condition} value={this.state.rconditionFiltre} onChange={(e) => this.setState({ rconditionFiltre: e.value })} autoWidth={false} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' />
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputNumber placeholder="Valeur en %" value={this.state.rval} onChange={(e) => this.setState({ rval: e.value })} />
                                </div>
                                <div className="col-12 md:col-3" >
                                    <InputText placeholder="Titre document" value={this.state.titreDoc} onChange={(e) => this.setState({ titreDoc: e.target.value })} />
                                </div>
                            </div>
                            <center>
                                <>
                                    <SplitButton label="Rechercher" style={{ width: '180px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items} />
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </>
                            </center>
                            <DataTable value={this.state.liste_data} style={{ minHeight: '600px', marginTop: 10 }}
                                dataKey="ordre" className="p-datatable-gridlines" showGridlines responsiveLayout="scroll" paginator rows={50}
                                footerColumnGroup={footerGroup}  >
                                <Column body={this.onIndexTemplate} header="No" style={{ width: '4%' }} />
                                <Column body={this.photoTemplate.bind(this)} header="Photo" style={{ width: '5%' }} />
                                <Column field="nometud" body={(rowData, column) => {
                                    return <div>
                                        <span >{rowData.nometud}</span><br />
                                        <span style={{ color: '#0b66b1' }}>{rowData.classe}</span>
                                    </div>
                                }} header="Nom" style={{ width: '20%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="ttrest" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.ttrest))}</span>
                                    </div>
                                }} header="Total_rest" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fipaye"  body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fipaye))}</span>
                                    </div>
                                }} header="Ins_payé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="firest"  body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.firest))}</span>
                                    </div>
                                }} header="Ins_rest" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fspaye"  body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fspaye))}</span>
                                    </div>
                                }} header="Sco_payé" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fsrest"  body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fsrest))}</span>
                                    </div>
                                }} header="Sco_rest" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fbpaye"  body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fbpaye))}</span>
                                    </div>
                                }} header="Badge_payé" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="fbrest"  body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'red' }}>{this.gemsysService.formaterValueSep(parseFloat(rowData.fbrest))}</span>
                                    </div>
                                }} header="Badge_rest" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}